import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import TrailerHeader from "../../components/trailerHeader/trailerHeader";
import TrailerTable from "../../components/trailerTable/trailerTable";
import React, { useState, useEffect, useContext, useCallback } from "react";
import {getAllTrailersbyTerminalID, getTrailer, getTrailerHistorybyTrailerid} from "../../services/trailerService";
import TrailerByIdHeader from "../../components/trailerByIdHeader/trailerByIdHeader";
import TrailerBodyForDetails from "../../components/trailerBodyForDetails/trailerBodyForDetails";
import TrailerBodyForSpecification from "../../components/trailerBodyForSpecification/trailerBodyForSpecification";
import TrailerBodyForHistory from "../../components/trailerBodyForHistory/trailerBodyForHistory";
import { toast } from 'react-toastify';
import { useNavigate, useParams,useLocation } from "react-router-dom";
import {getTerminalByTerminalIds} from "../../services/terminalService";
import {getAllCommodityGroups} from "../../services/commoditygroupService";
import {getAppFilter} from "../../services/appFilterService";
import { ContextData } from "../../components/appsession";
import { DateTime } from "luxon";
import { getDecodedURI,getEncodedURI } from "../../utils/urlEncodeandDecode";

function Trailers(location) {
  const [userData, setuserData] = useContext(ContextData);
  const { id } = useParams();

  const [allTrailers, setallTrailer] = useState([]);
  const [allTrailerCount, setallTrailerCount] = useState("");
  const [trailerClicked, settrailerClicked] = useState(false);
  const [trailerById, settrailerById] = useState({});
  const [headerTabName, setheaderTabName] = useState("details");
  const [commodity, setCommodity] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [terminalsOptions, setTerminalsOptions] = useState([]);
  const [trailerlistData, setTrailerlistData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trailerHistory, setTrailerHistory] = useState([]);
  const [trailerLastLocation,setTrailerLastLocation]=useState({})
  // const [isDisabled, setIsDisabled] = useState(false);
  const history = useNavigate();
  const locations= useLocation();

  const handelcallbackFromHeader = (childdata) => {
    settrailerClicked(childdata);
    setheaderTabName("details");
    history({pathname:locations.pathname,search:`${getEncodedURI({})}`});
  };

  useEffect(()=>{
    if(allTrailers?.length>0){
    let params=getDecodedURI(locations.search);
     if(params?.trailerId){
      let trailer=allTrailers?.find((el)=>el.trailer_id==params?.trailerId)
      handelcallback(true,trailer);
      setheaderTabName('History')
        }
      }
   },[allTrailers])

   const loadData = async () => {
    try {
      const planning_terminal_ids = userData.planningprofiles?.map(it => it?.userPlanningprofile?.terminal_id);

      if (planning_terminal_ids?.length > 0) {
        setIsLoading(true);

        let terminalinformationlist = await getTerminalByTerminalIds(planning_terminal_ids);
        setTerminalsOptions(terminalinformationlist);

        let filterData = { terminal_id: planning_terminal_ids };

        const trailerslist = await getAllTrailersbyTerminalID(filterData);
        setallTrailer(trailerslist);
        setTrailerlistData(trailerslist);
        setallTrailerCount(trailerslist.length);

        setIsLoading(false);
      }
    } catch (err) {
      console.log(err)
      setIsLoading(false); // Ensure loading state is reset on error
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error(err, { autoClose: false });
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [userData]);


  const handelcallback =async (childdata, trailer) => {
    if (trailer.trailer_id) {
        setIsLoading(true);
        let trailerHistoryResult =
        await getTrailerHistorybyTrailerid(
          trailer.trailer_id
          );
         setTrailerLastLocation(()=>{
          return {lat:trailerHistoryResult[0]?.location_latitude,lng:trailerHistoryResult[0]?.location_longitude}
         }) 
        // setDataResult(process(newData, dataState));
        setTrailerHistory(trailerHistoryResult);
        setIsLoading(false);
      }
    settrailerById(trailer);
    settrailerClicked(childdata);
    
    
    
  };

  const handelcallbackFromLocationHeader = (childdata) => {
    setheaderTabName(childdata);
  };

  useEffect(() => {
    if (id !== undefined) {
      const t = getTrailer(id)
        .then(function (trailer) {
          // settrailerById(trailer);
          handelcallback(true, trailer);
        })
        .catch(function (err) {
          toast.error(err, "Error");
        });
    }
  }, [id]);

  useEffect(() => {
    const commodity = getAllCommodityGroups().then((res) => {
      setCommodity(res);
    });

    const appFilter = getAppFilter();
    setRegionOptions(appFilter.region);
  }, []);
  const formatDate = (epoch_date, tzone) => {

    if (!tzone || tzone === "Eastern Standard Time") {
      tzone = "America/New_York"
    }
    if (tzone === "Central Standard Time") {
      tzone = "America/Chicago"
    }
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return "No Data"
    }
  
  }
  const handelcallbackFromTrailer = (childData) => {
    if(childData.length > 0){
      const tempallTrailer = [...trailerlistData];
      const tempArray = [];
      tempallTrailer?.map((el) => {
        if (childData.indexOf(el.terminal?.terminal_id) > -1) {
          tempArray.push(el);
        }
      });
      setallTrailer(tempArray);
    }else{
      setallTrailer(trailerlistData);
    }
  }
  const convertDateTime = (epoch_date)=>{
    return (
      <td>
        {DateTime.fromMillis(parseInt(epoch_date * 1000)).toFormat("MM-dd-yyyy, hh:mm").toString()}
      </td>
    )
  }
  return (
    <div id="wrapper">
      <Header
        userclicked={trailerClicked}
        parentcallback={handelcallbackFromHeader}
      ></Header>
      <AppBar></AppBar>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            {!trailerClicked ? (
              <>
                <TrailerHeader
                  allTrailers={allTrailers}
                  terminalsOptions={terminalsOptions}
                  commodity={commodity}
                  regionOptions={regionOptions}
                  handelcallbackFromTrailer={handelcallbackFromTrailer}
                />
                <TrailerTable
                  allTrailers={allTrailers}
                  setallTrailer={setallTrailer}
                  isLoading = {isLoading}
                  parentcallback={handelcallback}
                  convertDateTime={convertDateTime}
                />
              </>
            ) : (
              <>
                <TrailerByIdHeader
                  trailerById={trailerById}
                  parentcallback={handelcallbackFromLocationHeader}
                  convertDateTime={convertDateTime}
                  isLoading={isLoading}
                  trailerLastLocation={trailerLastLocation}
                />
                {headerTabName === "details" ? (
                  <TrailerBodyForDetails trailer={trailerById} />
                ) : headerTabName === "Specifications" ? (
                  <TrailerBodyForSpecification trailer={trailerById} />
                ) : headerTabName === "History" ? (
                  <TrailerBodyForHistory trailerById={trailerById} trailerHistory={trailerHistory} isLoading={isLoading}/>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trailers;
