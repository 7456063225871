import React, { useEffect, useContext, useState, createContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../header";
import AppBar from "../appbar";
import { getUser } from '../../services/userService';
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication,InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import { callMsGraph } from '../../appSession'
// Creating the context to have the role & access to be stored here.
const ContextData = createContext();
import axios from "axios";
import {toast} from 'react-toastify'
import errorMessageDisplay from '../../utils/errorMessageDisplay';
import packageJSON from '../../../package.json'

export { ContextData };


function AppSession(props) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [userAccountData, setuserAccountData] = useState({})
  const [isUserInfoReceived, setisUserInfoReceived] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const [userUniqueId, setUserUniqueId] = useState("");


  const getUserInfoMSGraph = async function (uniqueId) {
    const uniqueid = localStorage.getItem('uniqueid');
    const token = localStorage.getItem('accesstoken');
    if (uniqueId) {
      // const userId = emailAddress.toString().split("@")[0];
      //This Part gets the User
      try {
        const user = await getUser(uniqueId);
        if (user.length === 0) {
          const userData = await callMsGraph(token);
          if (userData) {
            localStorage.setItem("appsession", userData[0]);
            setuserAccountData(prev => userData[0]);
            setisUserInfoReceived(false);
          }
          else {
            // history.push("/")
            instance.loginRedirect(loginRequest).catch((e) => {
              console.error(e);
            })
          }
        }
        else {
          localStorage.setItem("appsession", user[0]);
          setuserAccountData(prev => user[0]);
          setisUserInfoReceived(true);
        }
      }
      catch(err){
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        } else if(err?.response?.data[0]?.message?.length>0){
          toast.error(err?.response?.data[0]?.message, { autoClose: false } );
        }else{
          toast.error('Failed to call MS graph API', { autoClose: false } );
        }
      } 
    }
  }

  const handleTokenError = async (error) => {
    if (error instanceof InteractionRequiredAuthError) {
        const msalResponse = await instance.acquireTokenRedirect({
            ...loginRequest,
            account: account
        });
        idToken = msalResponse.idToken;
        localStorage.setItem("idtoken", idToken);
        localStorage.setItem("accesstoken", msalResponse.accessToken);
        if(msalResponse?.uniqueId){
          getUserInfoMSGraph(msalResponse.uniqueId);
          setUserUniqueId(msalResponse.uniqueId);
        }
    } else {
        console.error("Token acquisition failed", error);
        toast.error('Failed to acquire token', { autoClose: false } );
    }
  }
  
  useEffect(() => {
    if (isAuthenticated && account) {
      instance.acquireTokenSilent({
        ...loginRequest,
        account: account
      }).then((response) => {
        localStorage.setItem("accesstoken", response.accessToken);
        localStorage.setItem("idtoken", response.idToken);

        // axios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
        getUserInfoMSGraph(response.uniqueId);
        setUserUniqueId(response.uniqueId);
      }).catch((error) => {
        handleTokenError(error);
      })
       
    }
  }, [isAuthenticated, account])

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None && account) {
      instance.acquireTokenRedirect({
        ...loginRequest,
        account: account
      }).then((response) => {
        localStorage.setItem("accesstoken", response.accessToken);
        localStorage.setItem("idtoken", response.idToken);
        setUserUniqueId(response.uniqueId);
        getUserInfoMSGraph(response.uniqueId);
      })
    }
  }, [isAuthenticated, inProgress, instance]);

  return (
    <>
      {
        userAccountData !== undefined ? (
          <ContextData.Provider value={[userAccountData, setuserAccountData, getUserInfoMSGraph, userUniqueId]}>
            {props.children}
          </ContextData.Provider>
        ) :
          (
            <div id="wrapper">
              <Header></Header>
              <AppBar></AppBar>
              <div className="card" style="width: 18rem;">
                <div className="card-body">
                  <h5 className="card-title">Session Error</h5>
                  <h6 className="card-subtitle mb-2 text-muted">There is no user session!</h6>
                  <p className="card-text">Either your account is not valid or your account is yet to be created. Please try refreshing the page to initiate the session. If it does not work, please contact system administrator.</p>
                </div>
              </div>
            </div>
          )
      }
    </>
  );
}
export default AppSession;