import React, { useEffect, useState, useContext } from "react";
import { DateTime } from "luxon";
import { Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withStyles } from '@mui/styles';
import Trailer from "../../assets/images/baseline_trailer.png";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import PlannerBoardTrailerTooltip, { OrderPBToolTip } from './plannerboardtrailertooltip';
import { DropContext } from "../../pages/planner/planner";
import { TextField, Button as MUIButton, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, FormControl, Select } from "@mui/material";
import { ContextData } from "../appsession";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import PlannerBoardTooltip, {
  OrderTooltip,
  SamsaraPBToolTip,
  // OrderPBToolTip, 
  Order2PBToolTip,
  Order3PBToolTip,
  TrailerPBToolTip,
  ShiftToolTip
} from "../../components/plannerboarddriversection/plannerboardtooltip";
import Skeleton from '@mui/material/Skeleton';
import {removeTrailerfromorderfortrailerview } from '../../services/planningBoardService'

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />

));

const useStyles = makeStyles((theme) => ({}))

const PlannerBoardTrailersection = (props) => {
  const classes = useStyles();
  const dayToMillis = 86400000;
  const context = useContext(DropContext);

  const {
    hourArr,
    dragset,
    allTrailers,
    selectedTimezoneDate,
    tarilerViewData,
    settrailerViewPageNumber,
    trailerViewPageNumber,
    isEditPermission,
    setSuccessResponseMssg,
    realTimeofPlanningBoard,
    plannerTimezone,
    searchTrailerData
  } = props



  const [trailerData, setTrailerData] = useState([]);
 // const [trailerPageNumber, setTrailerPageNumber] = useState(1);
 const [page, setPage] = useState(context.trailerViewPageNumber);

 const handleLoadingBarOnTrailer = (TrailerId, isLoad) => {
  setTrailerData(trailerData?.map((el) => {
    if (el.trailer_id === TrailerId)
      return {
        ...el,
        isloading: isLoad,
      };
    return el;
  }));
}

  useEffect(() => {
    setTrailerData(
      searchTrailerData
    );

  }, [searchTrailerData, page]);

  const handleTrailerViewPagination = async (event, value) => {
    context.settrailerViewPageNumber(value);
    setPage(value);
  };
  // const getColorBasedOnCondition = (order) => {
  //   return order.orderStartTime >= selectedTimezoneDate ? "#6c757d" : "#000";
  // };
  
  const removeTrailer= async(i,trailer,order)=>{
    handleLoadingBarOnTrailer(trailer.trailer_id,true)
    try{
    let move_id=trailer?.movements?.filter((move)=>move.stostopData==null && move.movementData.movement_type=='L')[0]?.movementData?.load_id;
    if(move_id){
       let response=await removeTrailerfromorderfortrailerview(trailer.trailer_id,{load_id:move_id})
      if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
      let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
      toast.error(`${str}`, { autoClose: false } );
      handleLoadingBarOnTrailer(trailer.trailer_id,false)
    }else{
      setSuccessResponseMssg("Order Removed From Trailer Successfully");
    }
    await context.refreshDataFromAPI(context.payloadForRefreshData);
     }else{
      toast.error(`Movement Type Not Found`, { autoClose: false } );
      handleLoadingBarOnTrailer(trailer.trailer_id,false)
     }
  }
  catch(err){
    console.log(err)
    if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
      errorMessageDisplay(err?.response?.data?.errors);
    }else{
    toast.error('Failed to remove trailer', { autoClose: false } );
    }
    handleLoadingBarOnTrailer(trailer.trailer_id,false)
  }
  }
  return (
    <>
      <div className='main_planner_wrapper'>
        <div class='planner_main_container_outer'>
          <div className='planner_am_section'>HRS</div>
          <div className='planner_main_container'>
            {hourArr.map((item, key) => (
              <div key={key} className='planner_main_inner_container'>
                <div className='hrs_wrapper'>
                  <p className='hrs_wrapper_time_text'>
                    {item == "0"
                      ? `12  A.M`
                      : item == "12"
                        ? `12  P.M`
                        : item > 11
                          ? `${item - 12}  P.M`
                          : `${item}  A.M`}
                  </p>
                  <div className='planner_day_time_inner'>
                    <div className='vl_plannertankwash'></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class='planner_pm_section'>HRS</div>
        </div>
        <div className='scroll_plannerboard_inner'>
          {trailerData.length > 0 ? trailerData?.map((trailer, idx) => {
            return (
              <div key={idx} class='planner_main_container_outer'>
                <div className='planner_main_container_outer_image_container_right_text_wrapper'>
                  <div class='planner_main_container_outer_image_container'
                    aria-controls='customized-menu'
                    aria-haspopup='true'
                    variant='contained'
                    color='primary'
                  >
                    <PlannerBoardTooltip
                      tooltip={
                        <TrailerPBToolTip it={trailer} />
                      }
                    >
                      <div>
                        <img
                          src={Trailer}
                          alt='Baseline-Shiping'
                          title='Baseline-Shiping'
                          className='planner_left_image'
                        />
                      </div>
                    </PlannerBoardTooltip>

                    <p className='planner_main_container_outer_image_container_icon_text'>
                      {trailer.trailer_id}
                    </p>
                  </div>

                  <div className='planner_main_container_outer_image_container_right_text'>
                    <p>Order</p>
                    <p>Driver</p>
                    <p>Segment</p>
                  </div>
                </div>

                <div class='planner_dragable_container'>
                {
             realTimeofPlanningBoard >= selectedTimezoneDate && realTimeofPlanningBoard < (selectedTimezoneDate + dayToMillis) &&
          <div className="plannerboard_realtime_bar" style={{ left:(((realTimeofPlanningBoard - (DateTime.now().setZone(plannerTimezone).startOf("day").toMillis()))/dayToMillis)*100+"%")}}></div>
            }
                {trailer.isloading ? (
                         <Stack spacing={1}>
                           <div className='loader_skeleton'>
                             <Skeleton variant='rectangular' />
                           </div>
                         </Stack>
                        ) : (
                          <></>
                   )}
                  <div className='planner_tick_container'>
                    {dragset.map((it2, index) => (
                      <div
                        key={index}
                        className='planner_dragable_inner'
                        onDrop={(e) => {
                          let dragData
                          if (Object.keys(context.dragData)?.length == 0) {
                            dragData = JSON.parse(localStorage.getItem('draggedData'))
                          } else {
                            dragData = context.dragData
                          }
                          if ((dragData?.plannedOrUnplanned  ?  dragData?.plannedOrUnplanned == "center" : context.plannedOrUnplanned === "center" && dragData.dragFrom === "ordersFromTrailerView") || dragData?.dragFrom === "movementFromTrailerView") {
                           handleLoadingBarOnTrailer(trailer.trailer_id, true);
                          }
                          e.preventDefault();
                          e.stopPropagation();
                          context.setDropData({
                            drop_to: "planner_tick_container",
                            trailer_id: trailer.trailer_id,
                            index: index,
                          });
                        }}
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                      ></div>
                    ))}
                  </div>

                  <div class='planner_dragable_main_block'>
                  {trailer?.shifts
                    ?.filter(
                      (shift) =>
                        shift.shiftEndTime >= selectedTimezoneDate ||
                        shift.shiftStartTime < selectedTimezoneDate + dayToMillis
                    )
                    .map((shift, index) => (
                      (shift.shiftType === "start" &&
                        shift.movementData.pickup.location.code === "LAYOVER") ||
                      (shift.shiftType === "end" &&
                        shift.movementData.delivery.location.code === "LAYOVER") ? (
                        <div
                          key={index}
                          className={
                            (shift.shiftType === "start" &&
                              shift.movementData.pickup.location.code === "LAYOVER") ||
                            (shift.shiftType === "end" &&
                              shift.movementData.delivery.location.code === "LAYOVER")
                              ? "planner_shift_color"
                              : "planner_shift"
                          }
                          style={{
                            position: "absolute",
                            left:
                              shift.shiftStartTime >= selectedTimezoneDate
                                ? ((shift.shiftStartTime - selectedTimezoneDate) /
                                    dayToMillis) *
                                  100 +
                                  "%"
                                : "0%",
                            width: `${(((shift.shiftEndTime <
                              selectedTimezoneDate + dayToMillis
                              ? shift.shiftEndTime
                              : selectedTimezoneDate + dayToMillis) -
                              (shift.shiftStartTime >= selectedTimezoneDate
                                ? shift.shiftStartTime
                                : selectedTimezoneDate)) /
                              dayToMillis) *
                              100
                            }%`,
                            display:
                              (((shift.shiftEndTime <
                                selectedTimezoneDate + dayToMillis
                                ? shift.shiftEndTime
                                : selectedTimezoneDate + dayToMillis) -
                                (shift.shiftStartTime >= selectedTimezoneDate
                                  ? shift.shiftStartTime
                                  : selectedTimezoneDate)) /
                                dayToMillis) *
                                100 >
                                0
                                ? "block"
                                : "none",
                          }}
                          draggable
                          onDragStart={(e) => {
                            // alert("hi")
                            context.setDragData({
                              dragFrom: "Shift",
                              dragDataItem: {
                                movement: shift,
                                driverId: driver.driverId,
                              },
                            });
                          }}
                        >
                          {shift.shiftType === "start" || shift.shiftType === "end" ? (
                            <PlannerBoardTooltip
                              tooltip={<ShiftToolTip it={shift} />}
                            >
                              <div className="tooltip_row_block">
                                {shift.shiftType === "start" ? "SS" : "ES"}
                              </div>
                            </PlannerBoardTooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    ))}
                  <div class='planner_dragable_inner_main_block'>
                    <div class='planner_draggable_main_block_row'>
                      {trailer?.orders
                        ?.map((order, index) => {
                          if (
                            order.orderEndTime >= selectedTimezoneDate &&
                            order.orderStartTime <
                            selectedTimezoneDate + dayToMillis
                          ) {
                            let next
                            if (index < trailer?.orders.length - 1) {
                              next = trailer?.orders[index + 1];
                            }
                            let endTime
                            if (next != undefined) {
                              endTime = Math.min(order?.orderEndTime, next?.orderStartTime)
                            }
                            else {
                              endTime = order?.orderEndTime
                            }
                            return (

                              <div
                                key={index}
                                style={{
                                  ...order.style,
                                  position: "absolute",
                                  left:
                                    order.orderStartTime >=
                                      selectedTimezoneDate
                                      ? ((order.orderStartTime -
                                        selectedTimezoneDate) /
                                        dayToMillis) *
                                      100 +
                                      "%"
                                      : "0%",
                                  width: `${(((endTime <
                                    selectedTimezoneDate + dayToMillis
                                    ? endTime
                                    : selectedTimezoneDate +
                                    dayToMillis) -
                                    (order.orderStartTime >=
                                      selectedTimezoneDate
                                      ? order.orderStartTime
                                      : selectedTimezoneDate)) /
                                    dayToMillis) *
                                    100
                                    }%`,
                                  display:
                                    (((endTime <
                                      selectedTimezoneDate + dayToMillis
                                      ? endTime
                                      : selectedTimezoneDate +
                                      dayToMillis) -
                                      (order.orderStartTime >=
                                        selectedTimezoneDate
                                        ? order.orderStartTime
                                        : selectedTimezoneDate)) /
                                      dayToMillis) *
                                      100 >
                                      0
                                      ? "block"
                                      : "none",
                                      pointerEvents:  "all"
                                }}
                                className='planner_draggable_main_block_row_block planner_draggable_main_block_top_row_block'
                              >
                                <PlannerBoardTooltip
                                  tooltip={<OrderTooltip  it={trailer?.movements?.find(o => o.orderId2 === order.orderId && o.type == "L" && o.type == "E") ?? trailer?.movements?.find(o => o.orderId2 === order.orderId)} />}
                                >
                                  <div className='tooltip_row_block'>
                                    {order.orderId}
                                  </div>
                                </PlannerBoardTooltip>
                                {
                                  order?.planId == null ? 
                                  <div className='close_planning_outer'>
                                    <div
                                       className='close_planning orderidfix'
                                       style={trailer.isloading ? {pointerEvents:"none"} : {}}
                                       onClick={(i) => {
                                         if (isEditPermission) {
                                           removeTrailer(idx, trailer, order)
                                         }
                                         else {
                                           return toast.info("Sorry,You don't have the necessary permissions to perform this action.", "Information", 3000)
                                         }
                                       }
                                       }
                                     />
                                   </div>: "" 
                                }
                              </div>
                            );
                          }
                          return null

                        })}
                    </div>
                    {/* middleblock */}
                    <div class='planner_draggable_main_block_row planner_draggable_main_block_middle_row'>
                      {trailer?.drivers
                        ?.map((driver, index) => {
                          if (
                            driver.driverEndTime >
                            selectedTimezoneDate ||
                            driver.driverStartTime <
                            selectedTimezoneDate + dayToMillis
                          ) {
                            let next
                            if (index < trailer?.drivers.length - 1) {
                              next = trailer?.drivers[index + 1];
                            }
                            let endTime
                            if (next != undefined) {
                              endTime = Math.min(driver?.driverEndTime, next?.driverEndTime)
                            }
                            else {
                              endTime = driver?.driverEndTime
                            }
                            return (
                              <div
                                key={index}
                                style={{
                                  position: "absolute",
                                  left:
                                    driver.driverStartTime >=
                                      selectedTimezoneDate
                                      ? ((driver.driverStartTime -
                                        selectedTimezoneDate) /
                                        dayToMillis) *
                                      100 +
                                      "%"
                                      : "0%",
                                  width: `${(((endTime <
                                    selectedTimezoneDate + dayToMillis
                                    ? endTime
                                    : selectedTimezoneDate +
                                    dayToMillis) -
                                    (driver.driverStartTime >=
                                      selectedTimezoneDate
                                      ? driver.driverStartTime
                                      : selectedTimezoneDate)) /
                                    dayToMillis) *
                                    100
                                    }%`,
                                  display:
                                    (((endTime <
                                      selectedTimezoneDate + dayToMillis
                                      ? endTime
                                      : selectedTimezoneDate +
                                      dayToMillis) -
                                      (driver.driverStartTime >=
                                        selectedTimezoneDate
                                        ? driver.driverStartTime
                                        : selectedTimezoneDate)) /
                                      dayToMillis) *
                                      100 >
                                      0
                                      ? "block"
                                      : "none",
                                      pointerEvents: "all"
                                }}
                                class='planner_draggable_main_block_row_block planner_draggable_main_block_top_row_block'
                              >
                                <PlannerBoardTooltip tooltip={<div><SamsaraPBToolTip driver={driver} /></div>}>
                                  <div className='tooltip_row_block'>
                                    {driver?.driverId}
                                  </div>
                                </PlannerBoardTooltip>
                              </div>
                            );
                          }
                          return null

                        })}
                    </div>
                    <div class='planner_draggable_main_block_row'>
                      {trailer?.movements
                        ?.map((movement, index) => {
                          if (
                            movement.endTime >= selectedTimezoneDate ||
                            movement.startTime <
                            selectedTimezoneDate + dayToMillis
                          ) {
                            let next
                            if (index < trailer?.movements.length - 1) {
                              next = trailer?.movements[index + 1];
                            }
                            let endTime
                            if (next != undefined) {
                              endTime = Math.min(movement?.endTime, next?.startTime)
                            }
                            else {
                              endTime = movement?.endTime
                            }
                            return (
                              <div
                                key={index}
                                style={{
                                  ...movement.style,
                                  position: "absolute",
                                  left:
                                    movement.startTime >=
                                      selectedTimezoneDate
                                      ? ((movement.startTime -
                                        selectedTimezoneDate) /
                                        dayToMillis) *
                                      100 +
                                      "%"
                                      : "0%",
                                  width: `${(((endTime <
                                    selectedTimezoneDate + dayToMillis
                                    ? endTime
                                    : selectedTimezoneDate +
                                    dayToMillis) -
                                    (movement.startTime >=
                                      selectedTimezoneDate
                                      ? movement.startTime
                                      : selectedTimezoneDate)) /
                                    dayToMillis) *
                                    100
                                    }%`,
                                  display:
                                    (((endTime <
                                      selectedTimezoneDate + dayToMillis
                                      ? endTime
                                      : selectedTimezoneDate +
                                      dayToMillis) -
                                      (movement.startTime >=
                                        selectedTimezoneDate
                                        ? movement.startTime
                                        : selectedTimezoneDate)) /
                                      dayToMillis) *
                                      100 >
                                      0
                                      ? "block"
                                      : "none",
                                      pointerEvents:"all"
                                }}
                                className='planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block'
                                aria-controls='customized-menu'
                                aria-haspopup='true'
                                variant='contained'
                                color='primary'
                                onContextMenu={(e) => {
                                  let order_id = movement.orderId;
                                  let order = trailer?.orders?.filter(
                                    (order) => {
                                      return order.orderId === order_id;
                                    }
                                  )[0];
                                }}

                                draggable={movement.type=='PU' &&  movement?.movementData?.pickup?.plan_id==null ? true : false}
                                onDragStart={(e) => {
                                  context.setDragData({
                                    dragFrom: "movementFromTrailerView",
                                    dragDataItem: {
                                      movement,
                                      trailer
                                    }
                                  });
                                }}
                                onDragOver={(e) => e.preventDefault()}
                              >
                                {movement.type === "L" ||
                                  movement.type === "E" ? (
                                  <PlannerBoardTooltip
                                    tooltip={
                                      <Order2PBToolTip it={movement} />
                                    }
                                  >
                                    <div className='tooltip_row_block'>
                                      {movement.type}
                                    </div>
                                  </PlannerBoardTooltip>
                                ) : (
                                  ""
                                )}
                               {movement.type === "DT" ? (
                              <PlannerBoardTrailerTooltip tooltip={<OrderPBToolTip it={movement} />}>
                                <div className='tooltip_row_block'>
                                  {movement.type}
                                </div>
                              </PlannerBoardTrailerTooltip>
                              ) : (
                              (movement.type === "DEL" ||
                                movement.type === "DH" ||
                                movement.type === "HT" ||
                                movement.type === "SD" ||
                                movement.type === "PU" ||
                                movement.type === "RET" ||
                                movement.type === "SP") ? (
                                <PlannerBoardTrailerTooltip tooltip={<OrderPBToolTip it={movement} />}>
                                  <div className='tooltip_row_block'>
                                    {movement.type}
                                  </div>
                                </PlannerBoardTrailerTooltip>
                              ) : (
                                ""
                              )
                              )}
                                                             
                              </div>
                            );
                          }
                          return null

                        })}
                      <div>
                        </div>
                      </div>
                      <div>
                      </div>
                   </div>
                  </div>
                </div>
                <div class='planner_pm_section'></div>
              </div>
            )
          }
          ) : <div className="plannerText">No planning to display</div>}
        </div>
      </div>


      {!context.trailerTabLoading ? (
        <div className="pagination_adjust">
          {Object.keys(trailerData).length >
            0 ? (
            <Stack spacing={2}>
              <Pagination
                onChange={handleTrailerViewPagination}
                count={Math.ceil(
                  allTrailers?.length /
                  25
                )}
                page={ context.trailerViewPageNumber}               
                color='primary'
              />
            </Stack>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>

  )
}

export default PlannerBoardTrailersection;