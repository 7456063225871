import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "./dt/likelySubtags.json";
import currencyData from "./dt/currencyData.json";
import weekData from "./dt/weekData.json";
import numbers from "./dt/numbers.json";
import currencies from "./dt/currencies.json";
import caGregorian from "./dt/ca-gregorian.json";
import dateFields from "./dt/dateFields.json";
import timeZoneNames from "./dt/timeZoneNames.json";
import { Grid as MGrid } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import { sendPushNotification, updateSelectedPlanningProfileOfUser } from "../../services/userService";
import EditIcon from '@mui/icons-material/Edit';
import Modal from "react-bootstrap/Modal";
import { Autocomplete } from "@mui/lab";
import Button from "react-bootstrap/Button";
import { TextField } from "@mui/material";
import { getAllTerminals, upadteTerminalofOrder } from "../../services/terminalService";
import { toast } from "react-toastify";
import { createUnSplit } from "../../services/planningBoardService";
import CircularProgress from '@mui/material/CircularProgress';
import { checkForUnsplit } from "../../services/planningBoardService";
import Checkbox from '@mui/material/Checkbox';

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);

import esMessages from "./dt/es.json";
loadMessages(esMessages, "es-ES");
import { process } from "@progress/kendo-data-query";
import { ContextData } from "../../components/appsession";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "@mui/material";

import { DateTime, Duration } from "luxon";
import getRowDraggable from "../rowDraggable/rowDraggable";
import { DropContext } from "../../pages/planner/planner";
import "@progress/kendo-theme-default/dist/all.css";
import "./plannerBoardOrdersTable.css";
import { searchUnplanAndPlandOrder, removeTrailerFromOrderTab } from "../../services/OrderService";
import filterOperators from "../../utils/filterOperators";
import { createBpaAlert } from "../../services/alertService";
import { updatemultiplemovements } from "../../services/planningBoardService";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const DATE_FORMAT = "MM-dd-yyyy HH:mm a ZZZZ ";
const locales = [
  {
    language: "en-US",
    locale: "en",
  },
  {
    language: "es-ES",
    locale: "es",
  },
];

const PlannerBoardOrdersTable = (props) => {
  const { orderRefreshState, settabSelected, PlannedTab, setplannedTab, tabSelected, oderTabClickC, payloadForOrders, selectedPlanningProfile, refreshDataFromAPI, isEditPermission, plannerTimezone } = props;
  const _export = React.useRef(null);

  const exportExport = async () => {
    try {
      const search = await searchUnplanAndPlandOrder(tabName, searchTerm, sortTerms, 1, 1000000, selectedTimezoneDate, daysOutPlanned);
      const searchData = search?.data?.data;
      if (_export.current !== null) {
        _export.current.save(searchData);
      }
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to export excel', { autoClose: false });
      }
    }
  };

  const [isDataloaded, setIsDataLoaded] = useState(false);
  const [userData, setuserData, getUserInfoMSGraph, userUniqueId] = useContext(ContextData);
  const { setDragData, setPlannedOrUnplanned, selectedTimezoneDate } = useContext(DropContext);
  const [columnorder, setcolumnorder] = useState([])
  const [columnorderfordetail, setcolumnorderfordetail] = useState([])
  const [totalResult, setTotalResult] = useState();
  const [daysOutPlanned, setDaysoutPlanned] = useState(1);
  const [alignment, setAlignment] = React.useState('center');
  const [dataToggle, setDataToggle] = useState(false);
  const [currentLocale, setCurrentLocale] = React.useState(locales[0]);
  const [orders, setorders] = useState([]);
  const daysOut = [1, 2, 3, 4, 5, 6, 7]
  const [refreshUserPreferences, setRefreshUserPreferences] = useState(true);
  const [tabName, setTabName] = useState("unplanned");
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState([]);
  const [sortTerms, setSortTerms] = useState([]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [showTerminalModal, setShowTerminalModal] = useState(false);
  const [allTerminals, setAllTerminals] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState({});
  const [oldTerminalDetails, setOldTerminalDetails] = useState({});
  const [selectedOrderDetails, setSelectedOrderDetails] = useState({});
  const [showAuthorizedModal, setAuthorizedShowModal] = useState(false)
  const [authorizedEditIcon, setAuthorizedEditIcon] = useState(false)
  const [authValue, setAuthValue] = useState(false);
  const [removeTrailer, setRemoveTrailer] = useState(false);
  const [removeTrailerData, setRemoveTrailerData] = useState(null);
  const [unsplitModal, setUnsplitModal] = useState(false);
  const [moveTypeval, setMoveTypeval] = useState(null);
  const [moveIdval, setMoveIdval] = useState(null);
  const [unsplitconfirmval, setUnsplitconfirmval] = useState(false);
  const [brokerageFlag, setBrokerageFlag] = useState(false);

  const handleDaysout = (event) => {
    setDaysoutPlanned(event.target.value)
    let userDataPrefrence = getUserpreference();
    if (userDataPrefrence !== null) {
      userDataPrefrence.daysOut = event.target.value
      localStorage.setItem('userpreference', JSON.stringify(userDataPrefrence));
    }
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment === null) return;
    if(newAlignment=='right'){
      setPlannedOrUnplanned('left')
    }else{
      setPlannedOrUnplanned(newAlignment);
    }
    setAlignment(newAlignment);
  };

  // Data state for unplanned tab
  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: [{
        "field": "Terminal_Full_Name",
        "operator": "contains",
        "value": selectedPlanningProfile.terminal_id
      }],
    },
    sort: [{ field: 'Early_Pickup_Appt2', dir: 'asc' }],
  });

  const [dataResult, setDataResult] = useState([]);

  let columns = [
    { id: 0, name: "Terminal_Full_Name", title: "Terminal", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 1, name: "Order_ID", title: "Order ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 2, name: "Order_Status", title: "Order Status", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 3, name: "Move_ID", title: "Move ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 4, name: "pcmiler_distance", title: "Distance", width: "100px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 5, name: "Move_Status", title: "Move Status", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 6, name: "pcmilerduration", title: 'Duration', width: '200px', isSortable: true, isFilterable: true, filter: 'text' },
    { id: 7, name: "Customer", title: "Customer", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 8, name: "BOL", title: "BOL", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 9, name: "Consignee_Ref", title: "Consignee Ref", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 10, name: "PO_Lot", title: "PO Lot", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 11, name: "Commodity_Group", title: "Commodity Group", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 12, name: "Commodity", title: "Commodity", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 13, name: "Product", title: "Product", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 14, name: "HazMat", title: "HazMat", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 15, name: "HazMat_Code", title: "HazMat Code", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 16, name: "Ordered_Wgt", title: "Ordered Weight", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 17, name: "ActualWgt", title: "Actual Weight", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 18, name: "Driver_ID", title: "Driver ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 19, name: "Trailer_ID", title: "Trailer ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 20, name: "Tractor_ID", title: "Tractor ID", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 21, name: "Sent_To_Driver", title: "Sent to Driver", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 22, name: "Tankwash_WO", title: "Tankwash WO", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 23, name: "Driver_Load", title: "Driver Load", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 24, name: "Pickup_Type", title: "Pickup Type", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 25, name: "Pickup_Name", title: "Pickup Location", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 26, name: "Pickup_Address", title: "Pickup Address", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 27, name: "Pickup_City", title: "Pickup City", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 28, name: "Pickup_State", title: "Pickup State", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 29, name: "Pickup_Zip", title: "Pickup Zip", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 30, name: "Pickup_Timezone", title: "Pickup Timezone", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 31, name: "Driver_Unload", title: "Driver Unload", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 32, name: "Delivery_Type", title: "Delivery Type", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 33, name: "Delivery_Name", title: "Delivery Location", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 34, name: "Delivery_Address", title: "Delivery Address", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 35, name: "Delivery_City", title: "Delivery City", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 36, name: "Delivery_State", title: "Delivery State", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 38, name: "Delivery_Zip", title: "Delivery Zip", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 39, name: "Delivery_Timezone", title: "Delivery Timezone", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 40, name: "Drop_Flag", title: "Drop Flag", width: "200px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 41, name: "Early_Pickup_Appt2", title: "Early Pickup Appt", width: "280px", timeZone: "Pickup_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 42, name: "Late_Pickup_Appt2", title: "Late Pickup Appt", width: "280px", timeZone: "Pickup_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 43, name: "Pickup_Planned_Arrive2", title: "Pickup Planned Arrive", width: "280px", timeZone: "Pickup_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 44, name: "Pickup_Planned_Depart2", title: "Pickup Planned Depart", width: "280px", timeZone: "Pickup_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 45, name: "Pickup_ETA2", title: "Pickup ETA", width: "280px", timeZone: "Pickup_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 46, name: "Pickup_Actual_Arrive2", title: "Pickup Actual Arrive", width: "280px", timeZone: "Pickup_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 47, name: "Pickup_Actual_Depart2", title: "Pickup Actual Depart", width: "280px", timeZone: "Pickup_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 48, name: "Early_Delivery_Appt2", title: "Early Delivery Appt", width: "280px", timeZone: "Delivery_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 49, name: "Late_Delivery_Appt2", title: "Late Delivery Appt", width: "280px", timeZone: "Delivery_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 50, name: "Delivery_Planned_Arrive2", title: "Delivery Planned Arrive", width: "280px", timeZone: "Delivery_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 51, name: "Delivery_Planned_Depart2", title: "Delivery Planned Depart", width: "280px", timeZone: "Delivery_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 52, name: "Delivery_ETA2", title: "Delivery ETA", width: "280px", timeZone: "Delivery_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 53, name: "Delivery_Actual_Arrive2", title: "Delivery Actual Arrive", width: "280px", timeZone: "Delivery_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 54, name: "Delivery_Actual_Depart2", title: "Delivery Actual Depart", width: "280px", timeZone: "Delivery_Timezone", isSortable: true, isFilterable: true, filter: 'date' },
    { id: 55, name: "On_Hold", title: "On Hold", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 56, name: "On_Hold_Reason", title: "On Hold Reason", width: "280px", isSortable: true, isFilterable: true, filter: 'text' },
    { id: 57, name: "planningstatus", title: "Planning Status", width: "200px", isSortable: false, isFilterable: false, filter: 'text' },
    { id: 58, name: "authorized", title: "Authorized", width: "280px", isSortable: true, isFilterable: true, filter: 'text' }
  ];

  const dateColumns = ["Delivery_Actual_Depart2", "Delivery_Actual_Arrive2", "Delivery_ETA2", "Delivery_Planned_Depart2", "Delivery_Planned_Arrive2", "Late_Delivery_Appt2", "Early_Delivery_Appt2",
    "Pickup_Actual_Depart2", "Pickup_Actual_Arrive2", "Late_Pickup_Appt2", "Pickup_ETA2", "Pickup_Planned_Depart2", "Pickup_Planned_Arrive2", "Late_Pickup_Appt2", "Early_Pickup_Appt2"];

  // On tabSelected change, this useEffect will be triggered
  useEffect(() => {
    if (oderTabClickC >= 0 && tabSelected === "orders") {
      if (userData?.userpreferences?.orderstabsequence && userData?.userpreferences?.orderstabsequence.length > 0) {
        const filteredColumns = userData?.userpreferences?.orderstabsequence.filter((col) => col.name !== "Action");
        setcolumnorder(filteredColumns);
        setcolumnorderfordetail(filteredColumns);
      }
      else {
        setcolumnorder(columns);
        setcolumnorderfordetail(columns);
      };
    };
  }, [tabSelected, data]);

  const columnReorderChange = async function (event) {
    const newOrders = [];
    event.target._columns.forEach((it) => {
      if (it.field !== "expanded" && it.field !== 'Action') {
        newOrders.push({
          id: it.index,
          name: it.field,
          title: it.title,
          width: it.width,
          isSortable: it.sortable,
          isFilterable: it.filterable,
          filter: it.filter
        });
      }
    });

    // Storing selected planning profile in user's table for user
    let payload = {
      id: userData.id,
      userpreferences: {}
    };

    if (userData.userpreferences) {
      // If userpreferences has already orderstabsequence data and we are changing sequence.
      if (userData.userpreferences.orderstabsequence) {
        payload.userpreferences.orderstabsequence = newOrders;
      } else {
        // If userpreferences has already some data, but not orderstabsequence data
        payload.userpreferences.orderstabsequence = newOrders;
      }
      if (userData.userpreferences.trailertabsequence) {
        payload.userpreferences.trailertabsequence = userData.userpreferences.trailertabsequence;
      }
      if (userData.userpreferences.currentlySelectedPlanningProfile) {
        payload.userpreferences.currentlySelectedPlanningProfile = userData.userpreferences.currentlySelectedPlanningProfile;
      }
    } else {
      // If userpreferences has not any data.
      payload.userpreferences.orderstabsequence = newOrders;
    }

    try {
      setcolumnorderfordetail(newOrders)
      const updateOrdersResponse = await updateSelectedPlanningProfileOfUser(payload);
      // await getUserInfoMSGraph(userUniqueId);
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to handle column reorder change', { autoClose: false });
      }
    }
  };

  // Debounce function to delay function call 
  function debounce(func, delay) {
    let timeoutId;

    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  // Function for handling unplanned tab filters
  const dataStateChange = async (event) => {
    setDataState(event.dataState);
    const data = event?.dataState?.filter?.filters || [];
    const sortData = event?.dataState?.sort || [];
    const terms = data && data.length > 0 ? data.map(({ field, value, operator }) => {
      if (field == 'pcmilerduration') {
        return ({ field: 'pcmiler_duration', value, operator })
      } else if (["Delivery_Actual_Depart2", "Delivery_Actual_Arrive2", "Delivery_ETA2", "Delivery_Planned_Depart2", "Delivery_Planned_Arrive2", "Late_Delivery_Appt2", "Early_Delivery_Appt2",
        "Pickup_Actual_Depart2", "Pickup_Actual_Arrive2", "Late_Pickup_Appt2", "Pickup_ETA2", "Pickup_Planned_Depart2", "Pickup_Planned_Arrive2", "Late_Pickup_Appt2", "Early_Pickup_Appt2"]?.includes(field)) {
        return ({ field, value: DateTime.fromJSDate(value).toISODate(), operator })
      }
      else {
        return ({ field, value, operator })
      }
    }) : [];
    const sorts = sortData && sortData.length > 0 ? sortData.map(({ field, dir }) => ({ field, dir })) : [];
    setSearchTerm(terms);
    setSortTerms(sorts)
    setTakeNumberOfResult(event?.dataState?.take);
    setSkipNumberOfResult(event?.dataState?.skip);
    setPageNumber(Math.floor(event?.dataState?.skip / event?.dataState?.take) + 1);
    let payload = {
      userid: userData.id,
      screenname: "order",
      filter: data,
      sort: sortData
    }
    const terms2 = data && data.length > 0 ? data.map(({ field, value, operator }) => {
      if (["Delivery_Actual_Depart2", "Delivery_Actual_Arrive2", "Delivery_ETA2", "Delivery_Planned_Depart2", "Delivery_Planned_Arrive2", "Late_Delivery_Appt2", "Early_Delivery_Appt2",
        "Pickup_Actual_Depart2", "Pickup_Actual_Arrive2", "Late_Pickup_Appt2", "Pickup_ETA2", "Pickup_Planned_Depart2", "Pickup_Planned_Arrive2", "Late_Pickup_Appt2", "Early_Pickup_Appt2"]?.includes(field)) {
        return ({ field, value: DateTime.fromJSDate(value).toISODate(), operator })
      }
      else {
        return ({ field, value, operator })
      }
    }) : [];
    localStorage.setItem('userpreference', JSON.stringify({ ...payload, ['filter']: terms2 }));
    if (terms?.length == 0 && event?.dataState?.filter == null) {
      if (sorts.length == 0) {
        debouncedSearchUnplan(tabName, [], [], pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned);
      } else {
        debouncedSearchUnplan(tabName, [], sorts, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned);
      }
    }
  };

  // Getting user preferences from local storage
  const getUserpreference = () => {
    const data = JSON.parse(localStorage.getItem('userpreference'));
    return data || null;
  };

  useEffect(() => {
    if (searchTerm?.length > 0) {
      let searchterm = searchTerm.map((el) => {
        if (el.field == 'pcmilerduration') {
          return { ...el, ['field']: "pcmiler_duration" }
        } else {
          return el
        }
      })
      debouncedSearchUnplan(tabName, searchterm, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned)
    } else{
      debouncedSearchUnplan(tabName,searchTerm, sortTerms,pageNumber, takeNumberOfResult, selectedTimezoneDate,daysOutPlanned)
     }
  }, [searchTerm, pageNumber, takeNumberOfResult, tabName, daysOutPlanned, sortTerms])

  useEffect(() => {
    const userDataPrefrence = getUserpreference();
    if (userDataPrefrence !== null) {
      if (userDataPrefrence?.daysOut) {
        setDaysoutPlanned(userDataPrefrence?.daysOut)
      }
      if (Object.keys(userDataPrefrence)?.length > 0 && userDataPrefrence?.filter?.length > 0) {
        const terms2 = userDataPrefrence?.filter && userDataPrefrence?.filter.length > 0 ? userDataPrefrence?.filter.map(({ field, value, operator }) => {
          if (["Delivery_Actual_Depart2", "Delivery_Actual_Arrive2", "Delivery_ETA2", "Delivery_Planned_Depart2", "Delivery_Planned_Arrive2", "Late_Delivery_Appt2", "Early_Delivery_Appt2",
            "Pickup_Actual_Depart2", "Pickup_Actual_Arrive2", "Late_Pickup_Appt2", "Pickup_ETA2", "Pickup_Planned_Depart2", "Pickup_Planned_Arrive2", "Late_Pickup_Appt2", "Early_Pickup_Appt2"]?.includes(field)) {
            return ({ field, value: DateTime.fromISO(value).toJSDate(), operator })
          }
          else {
            return ({ field, value, operator })
          }
        }) : [];
        setSearchTerm(userDataPrefrence?.filter);
        setSortTerms(userDataPrefrence?.sort);
        setDataState({
          skip: 0,
          take: takeNumberOfResult,
          filter: {
            logic: "and",
            filters: terms2,
          },
          sort: userDataPrefrence?.sort,
        })
      } else {
        setSearchTerm([{
          "field": "Terminal_Full_Name",
          "operator": "contains",
          "value": selectedPlanningProfile.terminal_id
        }])
        setSortTerms([{ field: "Early_Pickup_Appt2", dir: "asc" }])
        setDataState({
          skip: 0,
          take: takeNumberOfResult,
          filter: {
            logic: "and",
            filters: [{
              "field": "Terminal_Full_Name",
              "operator": "contains",
              "value": selectedPlanningProfile.terminal_id
            }],
          },
          sort: [{ field: "Early_Pickup_Appt2", dir: "asc" }],
        })
      }
    } else {
      setSearchTerm([{
        "field": "Terminal_Full_Name",
        "operator": "contains",
        "value": selectedPlanningProfile.terminal_id
      }])
      setSortTerms([{ field: "Early_Pickup_Appt2", dir: "asc" }])
      setDataState({
        skip: 0,
        take: takeNumberOfResult,
        filter: {
          logic: "and",
          filters: [{
            "field": "Terminal_Full_Name",
            "operator": "contains",
            "value": selectedPlanningProfile.terminal_id
          }],
        },
        sort: [{ field: "Early_Pickup_Appt2", dir: "asc" }],
      })
    }
  }, [tabSelected, PlannedTab, refreshUserPreferences, selectedPlanningProfile]);



  useEffect(() => {
    if (data?.length === 0) {
      setIsDataLoaded(false);
    } else {
      setIsDataLoaded(true);
    }
  }, [isDataloaded]);

  // In this useEffect, time conversions are happening
  useEffect(() => {
    var arrayOfObj = [];
    let newOrderArr = [];
    if (alignment == "center") {
      newOrderArr = data?.filter(order => order.planningstatus === "Unplanned")
      setTabName("unplanned");
      setplannedTab(false);
      if (tabName == "unplanned") {
        debouncedSearchUnplan(tabName, searchTerm, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned)
      }
    } else if (alignment == "left") {
      if (tabName == "planned") {
        debouncedSearchUnplan(tabName, searchTerm, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned)
      }
      setplannedTab(true)
      setTabName("planned")
      setDaysoutPlanned(1);
    }else if (alignment == "right") {  
      debouncedSearchUnplan("brokerage", searchTerm, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned)
      setTabName("brokerage")
      setDaysoutPlanned(1);
    }
  }, [orderRefreshState, alignment]);

  const debouncedSearchUnplan = useCallback(
    debounce(async (tabName, searchTerms, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysout) => {
      setDataToggle(true);
      try {
        const terms = searchTerms?.length > 0 ? searchTerms.map(({ field, value, operator }) => {
          if (field == 'pcmilerduration') {
            return ({ field: 'pcmiler_duration', value, operator })
          } else {
            return ({ field, value, operator })
          }
        }) : [];
        const search = await searchUnplanAndPlandOrder(tabName, terms, sortTerms, pageNumber, takeNumberOfResult, DateTime.fromMillis(selectedTimezoneDate).setZone(plannerTimezone).toISODate(), daysout, plannerTimezone);
        const searchData = search?.data?.data;
        const searchCount = Number(search?.data?.count);
        setTotalResult(searchCount)
        setData(searchData)
        setDataToggle(false)
      }
      catch (err) {
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        } else {
          toast.error('Failed to handle debounce on search unplan', { autoClose: false });
        }
        setDataToggle(false)
      }
    }, 1000),
    []
  );

  useEffect(() => {
    setDataResult(data);
  }, [data]);

  const handleClear = () => {
    let data = getUserpreference();
    let defaultTerminal = [{
      "field": "Terminal_Full_Name",
      "operator": "contains",
      "value": selectedPlanningProfile.terminal_id
    }]
    if (data !== null) {
      data.filter = defaultTerminal;
      data.sort = [];
      data.daysOut = 1;
    } else {
      data = {
        userid: userData.id,
        screenname: "order",
        filter: defaultTerminal,
        daysOut: 1,
        sort: []
      };
    }
    setDaysoutPlanned(1)
    localStorage.setItem('userpreference', JSON.stringify(data));
    setSearchTerm(defaultTerminal);
    setSortTerms([{ field: "Early_Pickup_Appt2", dir: "asc" }])
    setDataState({
      skip: 0,
      take: takeNumberOfResult,
      filter: {
        logic: "and",
        filters: defaultTerminal,
      },
      sort: [{ field: "Early_Pickup_Appt2", dir: "asc" }],
    })
  };

  const expandChange = (event) => {
    let newData = dataResult.map((item) => {
      if (item.Move_ID === event.dataItem.Move_ID) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setDataResult(newData);
  };
  const handlePickupUnsplitModalOpen = async (moveType, moveId, orderId) => {
    let payload = {
      load_id: moveId,
      order_id: orderId
    }
    try {
      const data = await checkForUnsplit(payload);
      if (data) {
        setUnsplitModal(true);
        setMoveTypeval(moveType);
        setMoveIdval(moveId);
      } else {
        toast.error("Can't unsplit the move", { autoClose: false });
      }
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong While checking the Moves, Please Refresh Your Screen', { autoClose: false });
      }
    }
  }

  const handleDeliveryUnsplitModalOpen = async (moveType, moveId) => {
    setUnsplitModal(true);
    setMoveTypeval(moveType);
    setMoveIdval(moveId);
  }

  const handleUnsplitOrder = async () => {
    setUnsplitconfirmval(true);
    try {
      let payLoad = {}
      if (moveTypeval === "SD") {
        payLoad = {
          movement_id: moveIdval,
          unsplitType: "split delivery"
        }
      } else if (moveTypeval === "SP") {
        payLoad = {
          movement_id: moveIdval,
          unsplitType: "split pickup"
        }
      }
      const response = await createUnSplit(payLoad)
      toast.success("Movement Unsplited Successfully");
      debouncedSearchUnplan(tabName, searchTerm, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned);
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to unsplit the Moves', { autoClose: false });
      }
      debouncedSearchUnplan(tabName, searchTerm, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned);
    }
    setUnsplitModal(false);
    setUnsplitconfirmval(false);
  };

  const handleEditAuthorized = (event) => {
    setAuthorizedShowModal(true);
    setAuthorizedEditIcon(true);
    setAuthValue(event);
  };

  const handleCloseAuthorizeMovement = () => {
    setAuthorizedShowModal(false);
    setAuthorizedEditIcon(false);

  };

  const handleOk = async () => {
    setAuthorizedShowModal(false);

    try {
      let payload = {
        load_id: authValue?.Move_ID,
        authorized: !authValue.authorized,
      }
      const response = await updatemultiplemovements([payload]);
      let authData = dataResult.map((item) => {
        if (item.Move_ID === authValue.Move_ID) {
          item.authorized = response.data[0].authorized;
        }
        return item;
      });
      setDataResult(authData);
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to submit', { autoClose: false });
      }
    }
  }


  const DetailComponent = props => {
    const dataItem = props.dataItem

    return (
      <div style={{ display: "flex", gap: "0.5em" }} >
        {
          columnorderfordetail?.map((el) =>

            <div style={{ fontWeight: "600", width: `${el?.width}` }}>
              {
                el.title === "Authorized" ?
                  `${el?.title}: ${dataItem[el?.name] ?? "false"}`
                  :
                  `${el?.title} : ${dataItem[el?.name] ?? ""}`
              }

            </div>

          )
        }
      </div>
    )
  };

  useEffect(() => {
    (async () => {
      try {
        const allTerminalsData = await getAllTerminals();
        if (allTerminalsData.length > 0) {
          setAllTerminals(allTerminalsData);
        }
      } catch (err) {
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        } else {
          toast.error('Failed to fetch all terminals', { autoClose: false });
        }
      }
    })();
  }, []);




  // Terminal change handler
  const terminalModalShowHandler = async (props) => {
    if (allTerminals.length > 0) {
      const filterData = allTerminals.find((data) => data.terminal_id === props.Terminal_ID);
      setSelectedTerminal(filterData);
      setOldTerminalDetails(props);
      // Storing order data
      setSelectedOrderDetails(props);
      setShowTerminalModal(true);
    }
  };

  const handleTerminalChange = (e, value) => {
    setSelectedTerminal(value);
  }

  const handleBrokerageChange = (event) => {
    setBrokerageFlag(event.target.checked);
  };

  // For change button
  const terminalSaveHandler = async () => {
    try {
      let payload={};
      payload.movement_id = selectedOrderDetails?.Move_ID     
      if(selectedTerminal?.terminal_id !== oldTerminalDetails?.Terminal_ID){
        payload.terminal_id = selectedTerminal?.terminal_id
       }

      if(brokerageFlag){
        payload.brokerage = brokerageFlag ? 'Y' : 'N';
      }

      const response = await upadteTerminalofOrder(payload);

      if (response?.data?.status === 'success') {
        toast.success("Terminal changed successfully.");
        setShowTerminalModal(false);
        if(selectedTerminal?.terminal_id !== oldTerminalDetails?.Terminal_ID){

        const payload = {
          text: `Order Id - ${selectedOrderDetails?.Order_ID} transferred from Terminal Id - ${oldTerminalDetails?.Terminal_ID} to Terminal Id - ${selectedTerminal?.terminal_id}`,
          lm_order_id: selectedOrderDetails?.Order_ID,
          load_id: selectedOrderDetails?.Move_ID,
          shipper: selectedOrderDetails.Pickup_Name.split("-")[0],
          consignee: selectedOrderDetails.Delivery_Name.split("-")[0],
          alert_type: "Transferred Orders",
          terminal_id: selectedTerminal?.terminal_id,
          commodity: selectedOrderDetails?.['Commodity Code'],
          commodity_group_description: selectedOrderDetails?.Commodity,
          created_on: DateTime.now().toMillis(),
          created_by: userData?.email?.split('@')[0],
          updated_on: DateTime.now().toMillis(),
          updated_by: userData?.email?.split('@')[0],
          // isdismissed: "", // boolean
          // is_dismissable: "", // boolean
          // status: "",
          // alert_comments: "",
          // reason_code_id: "",
        };

        const alertResponse = await createBpaAlert(payload);

        if (alertResponse.status === 201) {
          const data = alertResponse?.data?.data;
          let payload = {
            title: `Order Id - ${selectedOrderDetails?.Order_ID} Transferred`,
            body: `Order Id - ${selectedOrderDetails?.Order_ID} transferred from Terminal Id - ${oldTerminalDetails?.Terminal_ID} to Terminal Id - ${selectedTerminal?.terminal_id}`,
            url: `/alerts?type=transferred_orders&id=${data?.id}`,
            topic: `Terminal-${selectedTerminal?.terminal_id}`
          };

          await sendPushNotification(payload);
        }
      }
        setBrokerageFlag(false);
        debouncedSearchUnplan(tabName, searchTerm, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned);
      }else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
        errorMessageDisplay(response?.data?.errors);
        return;
      }
    } catch (err) {
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to save terminal', { autoClose: false });
      }
      debouncedSearchUnplan(tabName, searchTerm, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned);
    }
  };

  const terminalModalHideHandler = () => {
    setShowTerminalModal(false);
    setBrokerageFlag(false);
  };

  const handleRemoveTrailer = async () => {
    if (removeTrailerData) {
      try {
        let payLoad = {
          order_id: removeTrailerData?.Order_ID,
          trailer_id: removeTrailerData?.Trailer_ID
        }
        const response = await removeTrailerFromOrderTab(payLoad)
        setRemoveTrailerData(null);
        setRemoveTrailer(false);
        toast.success("Trailer removed successfully from the order");
        debouncedSearchUnplan(tabName, searchTerm, sortTerms, pageNumber, takeNumberOfResult, selectedTimezoneDate, daysOutPlanned)
      }
      catch (err) {
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        } else {
          toast.error('Error occured while removing the trailer', { autoClose: false });
        }
        setRemoveTrailerData(null);
        setRemoveTrailer(false);
      }
    }
  }

  return (
    <>
      {dataToggle && (
        <div className="loading-overlay active" style={{ fontSize: "18px" }}>
          <i className="fa fa-spinner fa-spin spinner-icon"></i>Loading...
        </div>
      )}
      <div className="tab_inside_section">
        <div className="tab_inside_section_top">
          <div className="tab_inside_section_top_left"></div>
          <div className="tab_inside_section_top_right">
            <div className='ordertabdropdown'>
              <form>
                <p className="ordertabdropdown_label">Days out</p>
                <select value={daysOutPlanned ? daysOutPlanned : 1} onChange={(e) => handleDaysout(e)}>
                  {daysOut.map(day => (
                    <option>{day}</option>
                  ))}
                </select>
              </form>
            </div>
            <div className='ordertabdropdown'>
              <button
                title="Export Excel"
                className='btn_white_sm ml_10'
                onClick={exportExport}
              >
                Export to Excel
              </button>
            </div>
            <div className="planning_board_tab_toggle">
              <MGrid
                container
                spacing={2}
                direction='column'
                alignItems='right'
              >
                <MGrid item>
                  <ToggleButtonGroup
                    size='small'
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    sx={{
                      '& .MuiToggleButton-root': {
                        backgroundColor: '#b53329',
                        color: 'white',
                        '&.Mui-selected': {
                          backgroundColor: '#5eab5e',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#5eab5e',
                            color: 'white',
                          },
                        },
                        '&:hover': {
                          backgroundColor: '#b53329',
                          color: 'white',
                        },
                      },
                    }}
                  >
                    <ToggleButton value='left' >PLANNED</ToggleButton>
                    <ToggleButton value='center' >UNPLANNED</ToggleButton>
                    <ToggleButton value='right' >BROKERAGE</ToggleButton> 
                  </ToggleButtonGroup>
                </MGrid>
              </MGrid>

            </div>
            <div>
              <button
                type='button'
                className='btn_white_sm ml_10'
                onClick={handleClear}
              >
                Clear
              </button>

            </div>
            {isDataloaded === "true" ? (
              ""
            ) : (
              <Link
                onClick={() => {
                  setIsDataLoaded(false);
                  const encodedPayload = encodeURIComponent(JSON.stringify(payloadForOrders));
                  window.open(`/orders?payload=${encodedPayload}`, "_blank");
                }}
              >
                <button variant="contained" type="button" className="btn_signout">
                  <i className="fa fa-external-link"></i>
                </button>
              </Link>
            )}
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={() => settabSelected("")}
            ></i>
          </div>
        </div>

        <div className="tab_inside_section_bottom">
          <div className="bottomright-with-nw-resize-outer">
            <LocalizationProvider language={currentLocale.language}>
              <IntlProvider locale={currentLocale.locale}>
                <ExcelExport
                  ref={_export}
                >
                  {
                    columnorderfordetail?.map((it) =>
                      <ExcelExportColumn
                        key={it.id}
                        field={it.name}
                        title={it.title}
                        width="200px"
                      />
                    )
                  }
                </ExcelExport>
                <Grid
                  detail={DetailComponent}
                  sortable={true}
                  filterable={true}
                  groupable={true}
                  filterOperators={filterOperators}
                  reorderable={true}
                  position={'top'}
                  total={totalResult}
                  pageable={{
                    pageSizes: [25, 50, 100],
                    info: true,
                    previousNext: true,
                  }}
                  skip={dataState.skip}
                  take={dataState.take}
                  sort={dataState.sort}
                  resizable={true}
                  data={dataResult}
                  {...dataState}
                  onDataStateChange={dataStateChange}
                  onColumnReorder={columnReorderChange}
                  rowRender={!dataToggle && getRowDraggable(
                    (e, dataItem) => {
                      setDragData({
                        dragFrom: "orders",
                        dragDataItem: dataItem,
                      });
                    },
                    (e, dataItem) => {
                      setDragData({});
                    }
                  )}
                  expandField="expanded"
                  onExpandChange={expandChange}
                >
                  {
                    tabName == 'unplanned' &&
                    <GridColumn
                      field="Action"
                      title="Action"
                      width="100px"
                      reorderable={false}
                      sortable={false}
                      filterable={false}
                      cell={(e) => {
                        return (
                          <td style={{ textAlign: "center" }}>
                            <EditIcon
                              style={{ cursor: "pointer" }}
                              onClick={(i) => terminalModalShowHandler(e.dataItem)}
                            />
                          </td>
                        );
                      }}
                    />
                  }
                  {
                    columnorder.map((it) => {
                      if (it.name == 'Delivery_Type') {
                        return <GridColumn
                          key={it.id}
                          field={it.name}
                          title={it.title}
                          width="150px"
                          filterable={it.isFilterable}
                          sortable={it.isSortable}
                          filter={it.filter}
                          cell={(e) => {
                            return (
                              <td>
                                <div style={{ display: "flex", gap: "8px" }}>
                                  {e.dataItem[e.field]}
                                  {e.dataItem[e.field] && tabName == 'unplanned' && e.dataItem['Delivery_Type'] == 'SD' ? <CallSplitIcon style={{ color: "#007aff", width: "25px", cursor: "pointer" }} onClick={() => { handleDeliveryUnsplitModalOpen(e.dataItem.Delivery_Type, e.dataItem.Move_ID) }} /> : ""}
                                </div>
                              </td>
                            );
                          }}
                        />
                      }
                      else if (it.name == 'Pickup_Type') {
                        return <GridColumn
                          key={it.id}
                          field={it.name}
                          title={it.title}
                          width="150px"
                          filterable={it.isFilterable}
                          sortable={it.isSortable}
                          filter={it.filter}
                          cell={(e) => {
                            return (
                              <td>
                                <div style={{ display: "flex", gap: "8px" }}>
                                  {e.dataItem[e.field]}
                                  {e.dataItem[e.field] && tabName == 'unplanned' && e.dataItem['Pickup_Type'] == 'SP' ? <CallSplitIcon style={{ color: "#007aff", width: "25px", cursor: "pointer" }} onClick={() => { handlePickupUnsplitModalOpen(e.dataItem.Pickup_Type, e.dataItem.Move_ID, e.dataItem.Order_ID) }} /> : ""}
                                </div>
                              </td>
                            );
                          }}
                        />
                      }
                      else if (it.name == 'Trailer_ID') {
                        return <GridColumn
                          key={it.id}
                          field={it.name}
                          title={it.title}
                          width="150px"
                          filterable={it.isFilterable}
                          sortable={it.isSortable}
                          filter={it.filter}
                          cell={(e) => {
                            return (
                              <td>
                                <div style={{ display: "flex", gap: "5px" }}>
                                  <a href={`${window.location.origin + '/trailers' + '?' + 'trailerId' + '=' + e.dataItem[e.field]}`} target='_blank' style={{ textDecoration: "underline" }}>{e.dataItem[e.field]}</a>
                                  {e.dataItem[e.field] && tabName == 'unplanned' && e.dataItem['Pickup_Type'] == 'PU' ? <CancelOutlinedIcon style={{ width: "20px", cursor: "pointer" }} onClick={() => { setRemoveTrailer(true), setRemoveTrailerData(e.dataItem) }} /> : ""}
                                </div>
                              </td>
                            );
                          }}
                        />
                      }
                      else if (it.name == 'authorized') {
                        return <GridColumn
                          key={it.id}
                          field={it.name}
                          title={it.title}
                          width="150px"
                          filterable={it.isFilterable}
                          sortable={it.isSortable}
                          filter={it.filter}
                          cell={(e) => {
                            return (
                              <td>
                                <div style={{ display: "inline-flex", alignItems: "center" }}>
                                  {e.dataItem.authorized ? "true" : "false"}
                                  {tabName == 'planned' && <EditIcon style={{ marginLeft: "0.5em", cursor: "pointer" }} onClick={(i) => handleEditAuthorized(e.dataItem)} />}
                                </div>
                              </td>

                            );
                          }}
                        />
                      } else if (dateColumns?.includes(it.name)) {
                        return <GridColumn
                          key={it.id}
                          field={it.name}
                          title={it.title}
                          width="200px"
                          filterable={it.isFilterable}
                          sortable={it.isSortable}
                          filter={it.filter}
                          cell={(e) => {
                            return (
                              <td >
                                {e.dataItem[it.name] ? DateTime.fromISO(e.dataItem[it.name]).setZone(plannerTimezone).toFormat("MM-dd-yyyy HH:mm ZZZZ") : ''}
                              </td>
                            );
                          }}
                        />
                      }

                      else {
                        return <GridColumn
                          key={it.id}
                          field={it.name}
                          title={it.title}
                          width="150px"
                          filterable={it.isFilterable}
                          sortable={it.isSortable}
                          filter={it.filter}
                        />
                      }
                    })
                  }
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </div>

      </div>
      <Modal
        show={showTerminalModal}
        onHide={() => terminalModalHideHandler()}
        // onShow={handleModalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter margin-top-bottom" className='text-bold'>
            Change Terminal Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='meterial_autocomplete'>
            <p >Select Terminal:</p>
            <Autocomplete
              id="combo-box-demo"
              options={allTerminals}
              getOptionLabel={(option) => `${option.full_terminal_name}`}
              value={selectedTerminal}
              onChange={handleTerminalChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Choose a Terminal..."
                  variant="outlined"
                />
              )}
            />
          </div>
          <div className="xmit_driver">
            <div>
              <Checkbox
                checked={brokerageFlag}
                onChange={handleBrokerageChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />  </div>
            <div>  Brokerage
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn_blue btn-blue" onClick={() => terminalSaveHandler()}>Change</Button>
          <Button className="btn_blue btn-blue" onClick={() => terminalModalHideHandler()}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      {authorizedEditIcon ? (<Modal show={showAuthorizedModal} onHide={() => handleCloseAuthorizeMovement()}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>
              Are you sure to change?</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", paddingTop: "20px", fontSize: "18px" }}>
          Are you sure to set the move from  <b>{authValue.authorized ? "Authorize " : "Unauthorize"}</b> to <b>{authValue.authorized ? "Unauthorize" : "Authorize"}</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAuthorizeMovement}>Close</Button>
          <Button variant="primary" onClick={handleOk}>Confirm</Button>
        </Modal.Footer>
      </Modal>) : null}

      <Modal
        show={removeTrailer}
        onHide={() => { setRemoveTrailer(false); setRemoveTrailerData(null) }}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Remove Trailer</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", paddingTop: "20px", fontSize: "18px" }}>
          <p>Do You Want To Remove The Trailer {`${removeTrailerData?.Trailer_ID}`} From Order {`${removeTrailerData?.Order_ID}`}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { setRemoveTrailer(false); setRemoveTrailerData(null) }}>Close</Button>
          <Button onClick={() => handleRemoveTrailer()} disabled={!isEditPermission}> Confirm</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={unsplitModal}
        onHide={() => { setUnsplitModal(false), setMoveTypeval(null), setMoveIdval(null) }}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body style={{ textAlign: "center", paddingTop: "20px", fontSize: "18px" }}>
          <p>Are you sure you want to <strong>Unsplit</strong> the Move <strong>{moveIdval}</strong> ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { if (unsplitModal) { setUnsplitModal(false), setMoveTypeval(null), setMoveIdval(null) } }} disabled={unsplitconfirmval}>Close</Button>
          {!unsplitconfirmval ? <Button onClick={() => handleUnsplitOrder()} disabled={!isEditPermission}> Confirm</Button> : <div className="spinner_modal_footer_md">
            <CircularProgress />
          </div>}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlannerBoardOrdersTable;