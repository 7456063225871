import React, { useState, useEffect, useContext } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { createRole, getAllPRole } from "../../services/roleService";
import Spinner from "react-bootstrap/Spinner";
import { ContextData } from "../../components/appsession";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import ProgressBar from "react-bootstrap/ProgressBar";
import { toast } from "react-toastify";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import PermissionLists from "../rolesAndPermissionPermissionList/PermissionList";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

import { process } from "@progress/kendo-data-query";

const errors = {
  color: "red",
  marginTop: "5px"
};

const RolesAndPermissionTable = (props) => {
  const [userData, setuserData] = useContext(ContextData);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [{
      field: '',
      dir: 'desc'
    }],
  });

  const { parentcallback, roles, accessDisabled } = props;

  const [modalShow, setModalShow] = useState(false);
  const [allroles, setallroles] = useState([]);

  const [dataResult, setDataResult] = useState(process(allroles, dataState));

  const [roleName, setRoleName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newRolePermission, setNewRolePermission] = useState([]);

  const dataStateChange = (event) => {
    setDataResult(process(allroles, event.dataState));
    setDataState(event.dataState);
  };


  useEffect(() => {
    setDataResult(process(allroles, dataState));
  }, [allroles]);

  const pageChange = (event) => {
    setPage(event.page);
  };
  const initialValues = {
    roleName: "",
  };

  const validation = yup.object().shape({
    roleName: yup
      .string()
      .required("Please enter a role name")
      .min(5, "Role name must be at least 5 characters"),
  });

  const showAssignedRoles = (permissions) => {
    let toolTips = "";

    for (let loop = 1; loop < permissions.length; loop++) {
      toolTips =
        toolTips +
        permissions[loop].permission +
        " [" +
        (permissions[loop].isEdit ? "Edit" : "View") +
        "], ";
    }

    return toolTips;
  };

  const rolesClickHandler = (e, role) => {
    parentcallback(true, role);
  };

  const save = async () => {
    try {
      const role = await createRole(roleName);
      if (role) {
        const allRolesAfterAdd = await getAllPRole();
        setallroles(allRolesAfterAdd);
        setModalShow(false);
        return toast.success("Role added successfully", "Success");
      }
    }catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error(err, { autoClose: false } );
      }
    }
  };

  const handleAddPermissionChecked = (perms) => {
    setNewRolePermission(perms);
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
  };


  useEffect(() => {
    if (roles.length > 0) {
      setallroles(roles);
    }
  }, [roles.length]);

  useEffect(() => {
    let arr = [];
    if (allroles.length > 0) {
      for (let index = 0; index < allroles.length; index++) {
        let a = allroles[index].rolespermission
          // .map((e) => e.permission +((e.isEdit && e.isView)?"(both)":(e.isEdit?"(edit)":""))  )
          .map((e) => {
            if ((e.can_edit && e.can_view)) {
              return e.permissiondetail.name + "(both)"
            }
            else {
              if (e.can_edit) {
                return e.permissiondetail.name + "(edit)"
              }
              if (e.can_view) {
                return e.permissiondetail.name + "(view)"
              }
            }
          }

          ).filter((el) => el != null)
          .join(" , ");

        arr.push(Object.assign(allroles[index], { allRoles: a }));
      }
      setallroles(arr);

    }
  }, [allroles.length]);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card card_shadow">
            <div className="card-body ">
              <div className="table-responsive">
                <div className="addbutton role_add_adjust">

                  <>

                    <button
                      type="button"
                      className="btn_blue btn-blue ml_10"
                      onClick={() => setModalShow(true)}
                    >
                      ADD
                    </button>
                  </>

                </div>

                {allroles?.length > 0 ? (
                  <Grid
                    filter={dataState.filter}
                    filterable={true}
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                      pageSizes: [5, 10, 20, 25, 50, 100],
                      info: true,
                      previousNext: true,
                      buttonCount: 10
                    }}
                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={dataResult}
                    onDataStateChange={dataStateChange}
                    onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                  >
                    <GridColumn field="name" title="Roles" width="500px" />

                    <GridColumn
                      field="allRoles"
                      title="Permission Name"
                      width="850px"
                    />
                  </Grid>
                ) : (

                  <div>
                    <ProgressBar animated now={100}
                    />
                    <div className='middle loader--text1'>

                    </div>
                  </div>
                )}
              </div>
            </div>


          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          enableReinitialize={true}
          onSubmit={save}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Add Role
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">ENTER ROLE NAME</label>
                  <input
                    type="text"
                    name="roleName"
                    className="form-control"
                    id="exampleFormControlInput1"
                    value={values.roleName}
                    onInput={(event) => setRoleName(event.target.value)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter role name"
                  />
                  {/* <PermissionLists
                    parentHandler={handleAddPermissionChecked}
                  ></PermissionLists> */}
                  <ErrorMessage
                    name="roleName"
                    render={(error) => <div style={errors}>{error}</div>}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={(e) => setModalShow(false)}>Close</Button>
                <Button
                  type="submit"
                // onClick={(e) => save()}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default RolesAndPermissionTable;
