import Header from "../../components/header";
import AppBar from "../../components/appbar";
import LandingContent from "../../components/landingcontent";
import Table from "../../components/landingcontenttable/userlistTable";
import React, { useState, useEffect, useContext, useCallback } from "react";
import Userprofileheader from "../../components/userprofileheader/userprofileheader";
import Userfiltertable from "../../components/userfiltertable/userfiltertable";
import Userrolefiltertable from "../../components/userrolefiltetable/userrolefiltertable";
import UserPlanningFiltertable from "../../components/userplanningprofileseclector/userplanningprofileselector";
import { getallpuser} from "../../services/userService";
import { callMsGraph } from "../../appSession";
import { getAllTerminals } from "../../services/terminalService";
import { ContextData } from "../../components/appsession";
import { getAllPRole } from "../../services/roleService";
import "bootstrap/dist/css/bootstrap.min.css";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import {toast} from 'react-toastify'
function Home() {
  const [userData, setuserData] = useContext(ContextData);
  const [isUserClicked, setisUserClicked] = useState(false);
  const [userProfileTabclicked, setuserProfileTabclicked] = useState(true);
  const [userid, setuserid] = useState("");
  const [allUser, setallUser] = useState([]);
  const [allUserData, setallUserData] = useState([]);
  const [rolesOptions, setrolesOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false)

  const handelcallback = (childdata, userid) => {
    setuserid(userid);
    setisUserClicked(childdata);
  };

  const handelcallbackFromHeader = async (childdata) => {
    setisUserClicked(childdata);

    setuserid("");
    const v = await getallpuser();
    setallUser(v);
    setallUserData(v);

  };

  const handelcallbackFromUserHeader = (childdata, user) => {

    if (childdata === "profile") {
      setuserProfileTabclicked(true);
    } else {
      setuserProfileTabclicked(false);
    }
  };

  const fetchUserData = async () => {
    try {
      const v = await getallpuser();
      setallUser(v);
      setallUserData(v);
    }catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }
    }
  };

  useEffect( () => {
    fetchUserData();
  }, [allUser.length > 0]);

  const fetchRoleData = async () => {
    try{
      const allrole = await getAllPRole();
      setrolesOptions(allrole);
    }catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
        toast.error(err,{ autoClose: false })
      }
    }
  }

  useEffect(() => {
    fetchRoleData();
  }, []);

  const handelcallbackFromRole = (childData) => {
    if (childData.length > 0) {
      const rolefilter = [...allUserData];

      const roleArray = [];
      rolefilter?.map((el) => {
        if (childData.indexOf(el.roles?.id) > -1) {
          roleArray.push(el);
        }
      });

      setallUser(roleArray);
    } else {


      setallUser(allUserData);
    }
  }

  useEffect(() => {
    setuserProfileTabclicked(true);
  }, [isUserClicked]);



  return (
    <>
      <div id="wrapper">
        <Header
          userclicked={isUserClicked}
          parentcallback={handelcallbackFromHeader}
        ></Header>
        <AppBar></AppBar>
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              {!isUserClicked ? (
                <>
                  <LandingContent
                    allUser={allUser}


                    rolesOptions={rolesOptions}

                    handelcallbackFromRole={handelcallbackFromRole}
                  />
                  <Table parentcallback={handelcallback} allUser={allUser} />
                </>
              ) : (
                <>
                  <Userprofileheader
                    parentcallback={handelcallbackFromUserHeader}

                    accessDisabled={isDisabled}
                    user={userid}

                  />
                  <div className="row special_row_flex">

                    <>
                      <Userrolefiltertable user={userid} accessDisabled={isDisabled}
                      //  userInfo={userData} 
                      />
                      <UserPlanningFiltertable user={userid} accessDisabled={isDisabled}
                        userInfo={userData}
                        updateUser={setuserData} />
                    </>

                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
