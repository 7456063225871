import React, { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import TankwashDataBar from "../tankwashDataBar/tankwashDataBar";
import { tankWashDragContext } from "../../pages/tankWash/tankWash";
import {
  getAllActiveTrailer, getAllActiveTractor, getWashCode, getTrailerWashWorkOrders, createTrailerWash, getWashLocationForVendorPayable, updateTrailerWash
} from "../../services/tankWashService";
import Modal from "react-bootstrap/Modal";
import { Autocomplete } from "@mui/lab";
import { TextField, Checkbox } from "@mui/material";
import IntraCompany from "./IntraCompany";
import OutsideCarrier from "./OutsideCarrier";
import VendorPayable from "./VendorPayable";
import { ContextData } from "../../components/appsession";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Skeleton from '@mui/material/Skeleton';
import { ProgressBar } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import { BaseConfig } from "../../baseConfig";
import axios from 'axios';

const TankwashdraggbleMainpage = (props) => {
  const {
    hourArr,
    dragset,
    selectedDateInToMilliSeconds,
    hoursOfOperation,
    selectedLocation,
    alllocationnamelist,
    orderClicked,
    modalShowEdit,
    setModalShowEdit,
    calenderDropdownSelectHandler,
    dateChangeThroughArrows,
    selectedDate,
    setcalenderDrpdownClicked,
    calenderDrpdownClicked,
    washDeatilsById,
    setorderClicked,
    washDetails
  } = props;

  const [timezone, setTimezone] = useState("");
  const [userData, setUserData] = useContext(ContextData);
  const userName = userData?.email?.split('@')[0];
  const companyName = userData?.email?.split('@')[1]?.split(".")[0].slice(0, 3).toUpperCase();
  const loggedUserData = { userName, companyName }

  const allTankwashDropdownOptions = [
    { id: "1", name: "Intra Company", code: "I" },
    { id: "2", name: "Outside Carrier", code: "O" },
    { id: "3", name: "Vendor Payable", code: "P" }
  ];

  const [visibleTankwashDropDownOptions, setVisibleTankwashDropDownOptions] = useState([
    { id: "1", name: "Intra Company", code: "I" },
    { id: "2", name: "Outside Carrier", code: "O" },
    { id: "3", name: "Vendor Payable", code: "P" }
  ]);
  const [dropDownSelection, setDropDownSelection] = useState({ id: "1", name: "Intra Company" });
  const [allActiveTrailersData, setAllActiveTrailersData] = useState([]);
  const [allActiveTractorsData, setAllActiveTractorsData] = useState([]);
  const [allWashCodes, setAllWashCodes] = useState([]);
  const [washLocationForVendorPayable, setWashLocationForVendorPayable] = useState([]);
  const [tankwashLocation, setTankwashLocation] = useState([]);

  const [selectedChargeType, setselectedChargeType] = useState({})
  const [isLoading, setIsLoading] = useState(props.tankwashLoading);
  // const [modalShow, setModalShow] = useState(washDetails.trailer_id?true:false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTab, setModalTab] = useState('edit');
  const [voidVal, setVoidVal] = useState();
  const [pdfUrl, setPdfUrl] = useState();
  const [ticketPdfData, setTicketPdfData] = useState([]);
 // const [tankwashLoading, setTankwashLoading] = useState(false);
 const [isDisabled,setIsDisabled]=useState(false);
 const checkAccess = () => {
  const permission = userData?.roles?.rolespermission.find(permission => permission?.permissiondetail?.code === "Locations")
  if(permission?.can_edit === false)
        setIsDisabled(true)
}
useEffect(()=>{
  checkAccess()
})
  useEffect(() => {
    // Tankwash location modification for Intra Company and Outside carrier 
    const tankwashLoc = alllocationnamelist.filter((loc) => (loc.is_terminal === true & loc.is_wash === true));
    setTankwashLocation(tankwashLoc);
  }, [alllocationnamelist]);

  const fetchData =async () => {
// Getting wash codes
const allWashCodeData = await getWashCode();
setAllWashCodes(allWashCodeData);

// Getting Active Trailer
const allActiveTrailerData = await getAllActiveTrailer();
const sortedTrailerData = [...allActiveTrailerData].sort((a, b) => {
  return parseInt(a.trailer_id, 36) - parseInt(b.trailer_id, 36);
});
setAllActiveTrailersData(sortedTrailerData);

// Getting Active Tractor
const allActiveTractorData = await getAllActiveTractor();
setAllActiveTractorsData(allActiveTractorData);

// Get Wash Locartion for outside carrier
const washLoactionForVendor = await getWashLocationForVendorPayable();
setWashLocationForVendorPayable(washLoactionForVendor);
  }
  
  useEffect( () => {
    fetchData();
  }, []);

  useEffect(() => {
    alllocationnamelist.map(item => { setTimezone(item.timezone) });
  })

  const context = React.useContext(tankWashDragContext);

  const getTimeZone = () => {
    if (!selectedLocation?.timezone) {
        console.error("Selected location or timezone is undefined");
        return "Unknown Timezone";
    }
    const tzone = selectedLocation.timezone;
    const localDateTime = DateTime.local().setZone(tzone);
    return localDateTime.isValid ? localDateTime.toFormat("ZZZZ") : tzone;
  };

  const dropDownTypeHandler = (e, type) => {

    setDropDownSelection(type);
  }

  const createTankWash = async (payload) => {
    try {
      const response = await createTrailerWash(payload);
      if (response.status === 200) {
        toast.success("Tankwash order created successfully", "Success", 2500);
        setModalShow(false);
        context.setdatarefresh(!context.datarefresh);
      }
    } catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Tankwash work order updation failed, Please Refresh Your Screen', { autoClose: false } );
      }
    }
  };

  const updateTankWash = async (payload, id) => {

    let data = JSON.stringify({
      "__type": payload.__type,
      "company_id": payload.company_id,
      "charge_type": payload.charge_type,
      "commodity_id": payload.commodity_id,
      "driver_id": payload.driver_id,
      "entered_user_id": payload.entered_user_id,
      "equip_type": payload.equip_type,
      "int_wash_code": payload.int_wash_code,
      "ext_wash_code": payload.ext_wash_code,
      "intra_co_seg_code": payload.intra_co_seg_code,
      "movement_id": payload.movement_id,
      "order_id": payload.order_id,
      "trailer_id": payload.trailer_id,
      "tractor_id": payload.tractor_id,
      "void": payload.void,
      "wash_location_id": payload.wash_location_id,
      "wash_date": payload.wash_date,
      "needed_date": payload.needed_date,
      "in_date": payload.in_date,
      "out_date": payload.out_date,
      "source": payload.source,
      "other_equip": payload.other_equip,
      "int_wash_amount": payload.int_wash_amount,
      "ext_wash_amount": payload.ext_wash_amount
    });
    
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${BaseConfig.PlanningApiEndPoint}/tankwash/workOrder/${id}`,
      data : data,
      headers: {
        'content-type': 'application/json',
        'accept': 'application/json'
      }
    };

    let response;

    try {
      response = await axios.request(config);
      context.setdatarefresh(!context.datarefresh);

      if (response.status === 200) {
        toast.success("Tankwash order updated successfully", "Success", 2500);
      } else {
        if (response.data.message === "value too long for type character varying(8)") {
          toast.error(`The "Trailer/Tractor" ID entered is too long!`, "Error");
        } else {
          toast.error("Tankwash work order updation failed.", "Error");
        }
      }

    } catch (error) {
      toast.error("Tankwash work order updation failed.", "Error");
      console.log(error);
    }

    return response;
  }

  const handleDateChange = (e) => {
    setIsLoading(true); 
    props.callback(true);

    calenderDropdownSelectHandler(e);
    setTimeout(() => {
      setIsLoading(false); // hide the loading indicator after 2 seconds
      props.callback(false);

    }, 2000);

  };

  const prevDateHandler = () => {
    setIsLoading(true); 
    props.callback(true);
    let prevDateTs = selectedDate - 86400000;
    dateChangeThroughArrows(prevDateTs);
    setTimeout(() => {
      setIsLoading(false); // hide the loading indicator after 2 seconds
      props.callback(false);

    }, 2000);
  };

  const nextDateHandler = () => {
    setIsLoading(true); 
    props.callback(true);
    let nextDate = selectedDate + 86400000;
    dateChangeThroughArrows(nextDate);
    setTimeout(() => {
      setIsLoading(false); // hide the loading indicator after 2 seconds
      props.callback(false);

    }, 2000);
  };

  const dropDownTypeHandlerforEdit = (e, type) => {
    setselectedChargeType(type)
  }

  useEffect(() => {
    if (washDeatilsById) {

      if (selectedLocation?.location_name?.split(' ')[0] === 'BULKMATIC') {
        setVisibleTankwashDropDownOptions([
          { id: "1", name: "Intra Company", code: "I" },
          { id: "2", name: "Outside Carrier", code: "O" }
        ]);
      } else {
        setVisibleTankwashDropDownOptions([
          { id: "3", name: "Vendor Payable", code: "P" }
        ]);
      }

      setselectedChargeType(allTankwashDropdownOptions.filter((e) => e.code === washDeatilsById.charge_type)[0])
      setVoidVal(washDeatilsById.void)
    }

  }, [washDeatilsById])

  const handleRefresh = async () => {
    if(selectedLocation?.code){
      setIsLoading(true); 
      props.callback(true);
      const needed_date_utc = DateTime.fromJSDate(selectedDate).toMillis();
      const wash_location_id = selectedLocation.code;
       await getTrailerWashWorkOrders({ needed_date_utc, wash_location_id })
       props.callback(false);
      setTimeout(() => {
        setIsLoading(false); 
      }, 2000);
    }
    else{
      return toast.error("Please select a location before refreshing.", "Error");
    }

  };

  const modalCloseHandler = () => {
    setModalShow(false);
  };

  const modalEditCloseHandler = () => {
    setModalShowEdit(false);
    setModalTab('edit');
  };

  const handleVoidValCheckboxChange = (event) => {
    // setVoidVal(event.target.checked ? 'N' : null);
    setVoidVal(event.target.checked ? 'Y' : 'N');
  };
  
  const showAddModal = () => {
    {
      setModalShow(true);
      if (selectedLocation?.location_name?.split(' ')[0] === 'BULKMATIC') {
        setDropDownSelection({ id: "1", name: "Intra Company", code: "I" });
        setVisibleTankwashDropDownOptions([
          { id: "1", name: "Intra Company", code: "I" },
          { id: "2", name: "Outside Carrier", code: "O" }
        ]);
      } else {
        setDropDownSelection({ id: "3", name: "Vendor Payable", code: "P" });
        setVisibleTankwashDropDownOptions([
          { id: "3", name: "Vendor Payable", code: "P" }
        ]);
      }
    }
  }
  
  return (
    <>
      {/* <ToastContainer position='top-right' autoClose={2000} theme='colored' /> */}
      <div className='row mt_30'>
        <div className='col-xl-12'>
          <div className='card card_shadow'>
            {isLoading || props.tankwashLoading?
             (
              <ProgressBar animated now={100} />
            ):(
              <>
           <div className='card-body'>
              {/* Start of Modal */}
              <div>
                <Modal
                  show={modalShow}
                  onHide={() => modalCloseHandler()}
                  dialogClassName="tankwash_order_modal_section modal-half"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Add Work Order
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="tankwash-dialog__container">
                      <div className="dialog-item">
                        <label htmlFor="typeHeadCommodityGroup">
                          Type
                        </label>
                        <div className="meterial_autocomplete">
                          <Autocomplete
                            id="combo-box-demo"
                            options={visibleTankwashDropDownOptions}
                            getOptionLabel={(option) => `${option?.name}`}
                            // defaultValue={visibleTankwashDropDownOptions[0]}
                            value={dropDownSelection}
                            // disabled={selectedLocation?.location_name?.split(' ')[0] === 'BULKMATIC' ? true : false}
                            onChange={dropDownTypeHandler}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Choose a Trailer..."
                                variant="outlined"
                              />
                            )}
                          />
                        </div>
                      </div>
                      {/* <div className="dialog-item">
                        <label htmlFor="typeHeadCommodityGroup">
                          Void (N)
                        </label>
                        <div className="meterial_autocomplete">
                          <Checkbox
                            checked={voidVal === 'N'}
                            onChange={handleVoidValCheckboxChange}
                          />
                        </div>
                      </div> */}
                    </div>

                    {/* Based on dropdown selection */}
                    {dropDownSelection?.name === "Intra Company" ? (
                      <IntraCompany
                        allWashLocation={tankwashLocation}
                        allActiveTractors={allActiveTractorsData}
                        allActiveTrailers={allActiveTrailersData}
                        allWashCodes={allWashCodes}
                        userData={userData}
                        loggedUserData={loggedUserData}
                        createTankWash={createTankWash}
                        dropDownSelection={dropDownSelection}
                        washDetails={washDetails}
                        voidVal={voidVal}
                        selectedLocation={selectedLocation}
                      />
                      ) : dropDownSelection?.name === "Outside Carrier" ? (
                        <OutsideCarrier
                        allWashLocation={tankwashLocation}
                        allActiveTractors={allActiveTractorsData}
                        allActiveTrailers={allActiveTrailersData}
                        allWashCodes={allWashCodes}
                        userData={userData}
                        loggedUserData={loggedUserData}
                        createTankWash={createTankWash}
                        dropDownSelection={dropDownSelection}
                        washDetails={washDetails}
                        voidVal={voidVal}
                        selectedLocation={selectedLocation}
                        
                        
                        />
                      ) : dropDownSelection?.name === "Vendor Payable" ? (
                        <VendorPayable
                        allWashLocation={washLocationForVendorPayable}
                        allActiveTractors={allActiveTractorsData}
                        allActiveTrailers={allActiveTrailersData}
                        allWashCodes={allWashCodes}
                        userData={userData}
                        loggedUserData={loggedUserData}
                        createTankWash={createTankWash}
                        dropDownSelection={dropDownSelection}
                        washDetails={washDetails}
                        voidVal={voidVal}
                        selectedLocation={selectedLocation}


                      />
                    ) : (<></>)
                    }

                  </Modal.Body>
                </Modal>
              </div>
              {/* End of Modal */}

              {/* Start of Edit Modal */}
              {orderClicked ? (
                <>
                  <div className="">
                    <Modal
                      show={modalShowEdit}
                      onHide={() => modalEditCloseHandler()}
                      dialogClassName="tankwash_order_modal_section modal-half"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          {/* Edit Work Order */}
                        {/* <div className='bottom_tab_wrapper'> */}
                          <div className='tab_button_section '>
                            <button
                              className={`tab_button ${modalTab === "edit" ? "active_tab" : ""}`}
                              onClick={(e) => setModalTab("edit")}
                            >
                              Edit Details 
                            </button>
                            <button
                              className={`tab_button ${modalTab === "images" ? "active_tab" : ""}`}
                              onClick={async (e) => {
                                setModalTab("images");

                                const url = BaseConfig.ApiEndPoint + `/tankwash/${washDeatilsById.wash_id}/documents`;
                                // const url = BaseConfig.ApiEndPoint + `/tankwash/102136/documents`;
                                const ticketImgRes = await axios.get(url);
                                const ticketImgData = ticketImgRes?.data;
                                setTicketPdfData([...ticketImgData]);
                                if (ticketImgData.length > 0) {
                                  setPdfUrl(ticketImgRes.data[0].url);
                                } else {
                                  setPdfUrl(null);
                                }
                              }}
                              
                            >
                              View Images
                            </button>
                          </div>
                          <p style={{margin:'18px 0'}}>Wash ID: {washDeatilsById.wash_id}</p>
                        {/* </div> */}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {modalTab === 'edit' && (
                          <div className="modal-edit-details">
                            <div className="tankwash-dialog__container">           
                              <div className="dialog-item">
                                <label htmlFor="typeHeadCommodityGroup">
                                  Type
                                </label>
                                <div className="meterial_autocomplete">
                                  <Autocomplete
                                    id="combo-box-demo"
                                    options={visibleTankwashDropDownOptions}
                                    getOptionLabel={(option) => `${option?.name}`}
                                    value={selectedChargeType}
                                    // disabled={selectedLocation?.location_name?.split(' ')[0] !== 'BULKMATIC' ? true : false}
                                    onChange={dropDownTypeHandlerforEdit}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Choose a Trailer..."
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="dialog-item">
                                <label htmlFor="typeHeadCommodityGroup">
                                  Void
                                </label>
                                <div className="meterial_autocomplete">
                                  <Checkbox
                                    // checked={washDeatilsById?.void === 'Y'}
                                    checked={voidVal === 'Y'}
                                    onChange={handleVoidValCheckboxChange}
                                  />
                                </div>
                              </div>
                            </div>


                            {/* Based on dropdown selection */}
                            {selectedChargeType?.name === "Intra Company" ? (
                              <IntraCompany
                                allWashLocation={alllocationnamelist}
                                allActiveTractors={allActiveTractorsData}
                                allActiveTrailers={allActiveTrailersData}
                                allWashCodes={allWashCodes}
                                userData={userData}
                                loggedUserData={loggedUserData}
                                createTankWash={createTankWash}
                                washDeatilsById={washDeatilsById}
                                updateTankWash={updateTankWash}
                                setorderClicked={setorderClicked}
                                voidVal={voidVal}
                                selectedLocation={selectedLocation}
                                modalShow={modalShow}
                                />
                              ) : selectedChargeType?.name === "Outside Carrier" ? (
                              <OutsideCarrier
                                allWashLocation={alllocationnamelist}
                                allActiveTractors={allActiveTractorsData}
                                allActiveTrailers={allActiveTrailersData}
                                allWashCodes={allWashCodes}
                                userData={userData}
                                loggedUserData={loggedUserData}
                                createTankWash={createTankWash}
                                washDeatilsById={washDeatilsById}
                                updateTankWash={updateTankWash}
                                setorderClicked={setorderClicked}
                                voidVal={voidVal}
                                selectedLocation={selectedLocation}
                                modalShow={modalShow}
                                />
                              ) : selectedChargeType?.name === "Vendor Payable" ? (
                              <VendorPayable
                                allWashLocation={washLocationForVendorPayable}
                                allActiveTractors={allActiveTractorsData}
                                allActiveTrailers={allActiveTrailersData}
                                allWashCodes={allWashCodes}
                                userData={userData}
                                loggedUserData={loggedUserData}
                                createTankWash={createTankWash}
                                washDeatilsById={washDeatilsById}
                                updateTankWash={updateTankWash}
                                setorderClicked={setorderClicked}
                                voidVal={voidVal}
                                selectedLocation={selectedLocation}
                                modalShow={modalShow}

                              />
                            ) : (<></>)
                            }
                          </div>
                        )}

                        {(modalTab === 'images') && (
                          (ticketPdfData.length > 0) ? (
                            <div className="modal-view-images" style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                              <table style={{fontSize:'24px', margin:'100px 0'}}>
                                <tr>
                                  <th>File</th>
                                  <th>Created on</th>
                                </tr>
                                {ticketPdfData.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{padding:'10px 20px'}}>
                                      <a style={{ textDecoration: "underline" }} href={item.url} target="_blank" rel="noopener noreferrer">
                                        {item.name}
                                      </a>
                                    </td>
                                    <td style={{padding:'10px 20px'}}>{item.createdOn}</td>
                                  </tr>
                                ))}
                              </table>

                            </div>
                          ) : (
                            <div style={{textAlign: 'center', padding: '20px', fontSize: '20px'}}>
                              <p>No files available for this work order.</p>
                            </div>
                          )
                        )}
                        {/* {(modalTab === 'images') && (
                          pdfUrl ? (
                            <div className="modal-view-images" style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                              <h4>Work Order: <a style={{textDecoration:"underline"}} href={pdfUrl} target='_blank'>{washDeatilsById?.wash_id}</a></h4>
                              <iframe
                                src={pdfUrl}
                                title='PDF Viewer'
                                width='50%'
                                height='500px'
                                style={{ border: 'none' }}
                              />
                            </div>
                          ) : (
                            <div style={{textAlign: 'center', padding: '20px'}}>
                              <p>No PDF available for this work order.</p>
                            </div>
                          )
                        )} */}

                      </Modal.Body>
                    </Modal>
                  </div>
                </>
              ) : ""}
              {/* End of Edit Modal */}
              <div className='tankwash_topdate1'>
                <div className="blank_space_tankwash"></div>

                <div className="date_container text-center">
                  <div className="tankwash_topdate_text">
                    <div className="planner_board_top_arrow">
                      <ArrowBackIosNew onClick={prevDateHandler} />
                    </div>
                    <div className="MuiFormControl-root MuiTextField-root css-i44wyl">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          className="date_picker"
                          size="small"
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          value={DateTime.fromMillis(selectedDate)}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="planner_board_top_arrow">
                      <ArrowForwardIos onClick={nextDateHandler} />
                    </div>
                  </div>

                  <div className="planner_top_date">
                    <div className="planner_dates_text1">
                      {getTimeZone()}
                    </div>
                  </div>
               
                </div>


                <div className='planning_board_button_container'>
                    <div className="planning_board_refresh_button">
                      <button type="button"
                        className="btn_blue btn-blue mb_0 access_save"
                        onClick={handleRefresh}
                      >Refresh</button>
                    </div>
                    <button
                  type="button"
                  onClick={() => {
                    showAddModal()
                  }}
                  className="btn_blue btn-blue mb_0 access_save"
                  disabled={isDisabled}
                >
                  Add Work Order
                </button>
                  </div>
              </div>

              <div className='tankwash_main_container'>
                {hourArr.map((item, index) => (
                  <div className='tankwash_main_inner_container' key={index}>
                    <div className='hrs_wrapper'>
                      <p className='hrs_wrapper_time_text'>
                        {item == "0"
                          ? `12  A.M`
                          : item == "12"
                            ? `12  P.M`
                            : item > 11
                              ? `${item - 12}  P.M`
                              : `${item}  A.M`}
                      </p>
                      <div className='tankwash_day_time_inner'>
                        <div className='vl_plannertankwash'></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="scroll_tankwash_inner">
                {context.baseData.map((it, idx) => (
                  <div key={idx}>
                    <div className='tankwash_dragable_container_label' key={idx}>
                      Bay {idx + 1}
                    </div>

                    <div className='tankwash_dragable_container'>
                      {dragset.map((it2, index) => (
                        <div
                        key={index}
                          onDrop={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            context.setDropData({ bay: idx, index: index });
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          className={
                            hoursOfOperation.includes(index) ? `tankwash_dragable_inner` : `tankwash_dragable_inner_dark`
                          }
                          style={
                            index == Math.floor(hoursOfOperation[0]) &&
                              !Number.isInteger(hoursOfOperation[0]) ?
                              {
                                width: "2.0833333333333335%",
                                background: `linear-gradient( to right, #545b71 0%, #545b71 ${Math.trunc(100 - (hoursOfOperation[1] - hoursOfOperation[0]) * 100)}%, #d1ddfa ${Math.trunc(100-(hoursOfOperation[1] - hoursOfOperation[0]) * 100)}%, #d1ddfa 100% )`
                              }
                              :
                              index == Math.floor(hoursOfOperation[hoursOfOperation.length - 1]) &&
                                !Number.isInteger(hoursOfOperation[hoursOfOperation.length - 1]) ?
                                {
                                  width: "2.0833333333333335%",
                                  border: "none",
                                  background: `linear-gradient( to left, #545b71 0%, #545b71 ${Math.trunc((hoursOfOperation[1] - hoursOfOperation[0]) * 100)}%, #d1ddfa ${Math.trunc((hoursOfOperation[1] - hoursOfOperation[0]) * 100)}%, #d1ddfa 100% )`
                                }
                                : {
                                  width: "2.0833333333333335%",
                                }
                          }

                        ></div>
                      ))}
                                          
                      <>
                        {it?.map((item, itemIndex) => {
                          return (
                            <>
                              {item?.isLoading ? (
                                <Stack spacing={1}>
                                  <div className='loader_skeleton'>
                                    <Skeleton variant='rectangular' />
                                  </div>
                                </Stack>
                              ) : <></>}
                            </>
                          )
                        })}

                        {it?.map((item, itemIndex) => (
                          <TankwashDataBar
                            barData={item}
                            bay={idx}
                            index={itemIndex}
                            key={itemIndex}
                            selectedLocation={selectedLocation}
                            selectedDateInToMilliSeconds={selectedDateInToMilliSeconds}
                          />
                        ))}
                      </>                    
                    </div>
                  </div>
                ))}
              </div>
            </div>
              </>
            ) }
           
          </div>
        </div>
      </div >
    </>
  );
};

export default TankwashdraggbleMainpage;
