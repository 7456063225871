import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import RolesAndPermissionHeader from '../../components/rolesAndPermissionHeader/rolesAndPermissionHeader';
import RolesAndPermissionTable from '../../components/rolesAndPermissionTable/rolesAndPermissionTable';
import RolesAndPermissionPermissionList from '../../components/rolesAndPermissionPermissionList/rolesAndPermissionPermissionList';
import React, { useState, useEffect, useContext } from 'react';
import {getAllPRole,getAllRole} from '../../services/roleService';
import { ContextData } from "../../components/appsession";
import errorMessageDisplay from '../../utils/errorMessageDisplay';
import { toast } from "react-toastify";
import axios from 'axios';

function Roles() {
  const [userData, setuserData] = useContext(ContextData);
  const [isRoleClicked, setisRoleClicked] = useState(false)
  const [role, setRole] = useState({});
  const [allRoles, setAllRoles] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [filterData, setfilterData] = useState("")
  const [roleCount, setRoleCount] = useState(0)
  const [isDisabled, setIsDisabled] = useState(false)

  const isAccess = () => {
    const permission = userData?.roles?.rolespermission?.
      map(permit => {
        if (permit?.permissiondetail?.code == "Roles" && permit?.can_edit == false) {
          setIsDisabled(true)
        }

      });
  }
  const getRoles = async () => {
    try{
    const roles = await getAllPRole();
    setAllRoles(roles)
    setRoleCount(roles.length);
    }catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error(err, { autoClose: false });
      }
    }
  }
  const handelcallback = (childdata, role) => {
    setRole(role);
    setisRoleClicked(childdata)
  }
  const handelcallbackFromHeader = (childdata) => {
    setAllRoles([])
    setisRoleClicked(childdata)
    getRoles()
  }
  const rolesHandler = async (isSaved) => {
    if (isSaved) {
      const allRoles = await getAllRole();
      setAllRoles(allRoles)
    }
  }
 const getRoleData = async () => {
  getRoles()
  isAccess()
 }
  useEffect(() => {
    getRoleData();
  }, [])

  return (
    <div id="wrapper">
      <Header userclicked={isRoleClicked} parentcallback={handelcallbackFromHeader}></Header>
      <AppBar></AppBar>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            {!isRoleClicked ?
              <>
                <RolesAndPermissionHeader roleCount={roleCount} />
                <RolesAndPermissionTable parentcallback={handelcallback} roles={allRoles} accessDisabled={isDisabled} />
              </>
              :
              <>
                <RolesAndPermissionPermissionList role={role} rolesHandler={rolesHandler} accessDisabled={isDisabled} />
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roles;