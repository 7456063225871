import React from "react";

const LocationHeader = (props) => {
  const {totalResult} = props;
  
  return (
    <div className="row df mt_30">
      <div className="col-xl-12">
        <div className="card special_bg card_shadow">
          <div className="card-body">
            <div className="row top_adjust">
              <div className="col-md-12">
                <h2 className="text-light">Locations</h2>
                <p className="user_sec_text">
                  Total: {totalResult}
                </p>
                <div className="col-md-12">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationHeader;
