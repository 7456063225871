import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import {
  IntlProvider,
  LocalizationProvider,
  IntlService,
} from "@progress/kendo-react-intl";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import getRowDraggable from "../rowDraggable/rowDraggable";
import { tankWashDragContext } from "../../pages/tankWash/tankWash";
import { DateTime } from "luxon";
import { Grid as MGrid } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import { ThrottlingConstants } from "@azure/msal-common";
import { ContextData } from '../../components/appsession';
import { updateplanningprofile, updateSelectedPlanningProfileOfUser } from "../../services/userService";

const DATE_FORMAT = "MM-dd-yyyy HH:mm a ZZZZ ";
const intl = new IntlService("en");

const DetailComponent = () => {
  return null;
};

const TankWashOrdersTable = (props) => {
  const [userData] = useContext(ContextData);
  const {  data, location, handleToggle, selectedValue, setSelectedValue, setselectedDate, calenderDropdownSelectHandler, genarateHosBlocKOfLocation, selectedLocation } = props;
  const context = React.useContext(tankWashDragContext);
  const [isDataloaded, setIsDataLoaded] = useState(false);
  const [allOrdersOfLocation, setAllOrdersOfLocation] = useState([]);
  const [filteredOrdersOfLocation, setFilteredOrdersOfLocation] = useState([]);
  const [columnorder, setcolumnorder] = useState([]);
  const [columnorderExcel, setcolumnorderExcel] = useState([]);
  const [sortDir, setSortDir] = useState('asc');

  const locales = [
    {
      language: "en-US",
      locale: "en",
    },
    {
      language: "es-ES",
      locale: "es",
    },
  ];

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [
      {
        field: "",
        dir: "asc",
      },
    ],
  
  });

  const [currentLocale, setCurrentLocale] = React.useState(locales[0]);
  const [orders, setorders] = useState([]);
  const [orderArr, setOrderArr] = useState(data);
  const _export = useRef(null);
  const [dataResult, setDataResult] = React.useState(
    process(orders, dataState)
  );
  
  let columns = [
    { id: 0, name: "expanded", title: "" },
    {id: 1,
      name: "wash_location_id",
      title: "Wash Location Id",
      width: "280px"},
    { id: 2, name: "wash_id", title: "Wash Id", width: "280px" },
    { id: 3, name: "status", title: "Status", width: "280px" },
    { id: 4, name: "trailer_id", title: "Trailer Id", width: "280px" },
    { id: 5, name: "commodity_id", title: "Commodity Id", width: "280px" },
    {
      id: 6,
      name: "int_wash_code",
      title: "Int Wash Code",
      width: "280px",
    },
    {
      id: 7,
      name: "ext_wash_code",
      title: "Ext Wash Code",
      width: "280px",
    },
    { id: 8, name: "needed_date_utc", title: "Needed Date", width: "280px" },
    { id: 9, name: "in_date_utc", title: "In Date Utc", width: "280px" },
    { id: 10, name: "out_date_utc", title: "Out Date Utc", width: "280px" },
    { id: 11, name: "company_id", title: "Company Id", width: "280px" },
    { id: 12, name: "charge_type", title: "Charge Type", width: "280px" },
    { id: 13, name: "customer_id", title: "Customer Id", width: "280px" },
    { id: 14, name: "driver_id", title: "Driver Id", width: "280px" },
    {
      id: 15,
      name: "entered_user_id",
      title: "Entered User Id",
      width: "280px",
    },
    { id: 16, name: "equip_type", title: "Equip Type", width: "280px" },
    {
      id: 17,
      name: "ext_wash_amount",
      title: "Ext Wash Amount",
      width: "280px",
    },
    {
      id: 18,
      name: "ext_wash_amount_c",
      title: "Ext_Wash_Amount C",
      width: "280px",
    },
    {
      id: 19,
      name: "ext_wash_amount_d",
      title: "Ext wash Amount D",
      width: "280px",
    },
    {
      id: 20,
      name: "ext_wash_amount_n",
      title: "Ext wash Amount N",
      width: "280px",
    },
    {
      id: 21,
      name: "ext_wash_amount_r",
      title: "Ext wash Amount R",
      width: "280px",
    },
    // {
    //   id: 15,
    //   name: "ext_wash_code",
    //   title: "Ext Wash Code",
    //   width: "280px",
    // },
    { id: 22, name: "hazmat_code", title: "Hazmat Code", width: "280px" },
    { id: 23, name: "heel", title: "Heel", width: "280px" },
    {
      id: 24,
      name: "heel_unit_type",
      title: "Heel Unit Type",
      width: "280px",
    },
    // { id: 19, name: "in_date", title: "In Date", width: "280px" },
    {
      id: 25,
      name: "int_wash_amount",
      title: "Int Wash Amount",
      width: "280px",
    },
    {
      id: 26,
      name: "int_wash_amount_c",
      title: "Int Wash Amount C",
      width: "280px",
    },
    {
      id: 27,
      name: "int_wash_amount_d",
      title: "Int Wash Amount D",
      width: "280px",
    },
    {
      id: 28,
      name: "int_wash_amount_n",
      title: "Int Wash Amount N",
      width: "280px",
    },
    {
      id: 29,
      name: "int_wash_amount_r",
      title: "Int Wash Amount R",
      width: "280px",
    },
    // {
    //   id: 25,
    //   name: "int_wash_code",
    //   title: "Int Wash Code",
    //   width: "280px",
    // },
    {
      id: 30,
      name: "intra_co_seg_code",
      title: "Intra Co Seg Code",
      width: "280px",
    },
    {
      id: 31,
      name: "invoice_po_date",
      title: "Invoice Po Date",
      width: "280px",
    },
    {
      id: 32,
      name: "invoice_po_num",
      title: "Invoice Po Num",
      width: "280px",
    },
    { id: 33, name: "movement_id", title: "Movement Id", width: "280px" },
    { id: 34, name: "order_id", title: "Order Id", width: "280px" },
    { id: 35, name: "other_equip", title: "Other Equip", width: "280px" },
    // { id: 32, name: "out_date", title: "Out Date", width: "280px" },
    {
      id: 36,
      name: "ready_to_xfer",
      title: "Ready To Xfer",
      width: "280px",
    },
    {
      id: 37,
      name: "seg_alloc_code",
      title: "Seg Alloc Code",
      width: "280px",
    },
    { id: 38, name: "tractor_id", title: "Tractor Id", width: "280px" },
    // { id: 35, name: "trailer_id", title: "Trailer Id", width: "280px" },
    { id: 39, name: "vendor_id", title: "Vendor Id", width: "280px" },
    { id: 40, name: "void", title: "Void", width: "280px" },
    { id: 41, name: "voucher_id", title: "Voucher Id", width: "280px" },
    { id: 42, name: "wash_date_utc", title: "Wash Date", width: "280px" },
    {
      id: 43,
      name: "wash_location_id",
      title: "Wash Location Id",
      width: "280px",
    },
    { id: 44, name: "washed_for", title: "Washed For", width: "280px" },
    {
      id: 45,
      name: "tank_wash_assigned",
      title: "Tank Wash Assigned",
      width: "280px",
    },
    {
      id: 46,
      name: "trailer_wash_assigned",
      title: "Trailer Wash Assigned",
      width: "280px",
    },
    // { id: 44, name: "needed_date", title: "Needed Date", width: "280px" },
    {
      id: 47,
      name: "commodity_desc2",
      title: "Commodity Desc2",
      width: "280px",
    },
    { id: 48, name: "text", title: "Text", width: "280px" },
    { id: 49, name: "bay", title: "Bay", width: "280px" },
    { id: 50, name: "source", title: "Source", width: "280px" },
    { id: 51, name: "resource", title: "Resource", width: "280px" },
    { id: 52, name: "history", title: "History", width: "280px" },
    { id: 53, name: "start_time_utc", title: "Start Time Utc", width: "280px" },
    { id: 54, name: "end_time_utc", title: "End Time Utc", width: "280px" },
    // { id: 54, name: "in_date_utc", title: "In Date Utc", width: "280px" },
    // { id: 55, name: "out_date_utc", title: "Out Date Utc", width: "280px" },
  ];

  const columnReorderChange = async function (event) {
    const newTankwashColumnOrder = [];

    event.target._columns.forEach((it) => {
      if (it.field !== "expanded" && it.field !== 'Action') {
        newTankwashColumnOrder.push({
          id: it.index,
          name: it.field,
          title: it.title,
          width: it.width,
        });
      }
    });

    // Storing selected planning profile in user's table for user
    let payload = {
      id: userData.id,
      userpreferences: {}
    };

    if (userData.userpreferences) {
      payload.userpreferences.tankwashtabsequence = newTankwashColumnOrder;
      
      if (userData.userpreferences.trailertabsequence) {
        payload.userpreferences.trailertabsequence = userData.userpreferences.trailertabsequence;
      }
      
      if (userData.userpreferences.currentlySelectedPlanningProfile) {
        payload.userpreferences.currentlySelectedPlanningProfile = userData.userpreferences.currentlySelectedPlanningProfile;
      }
    } else {
      payload.userpreferences.tankwashtabsequence = newTankwashColumnOrder;
    }

    setcolumnorderExcel(newTankwashColumnOrder);
    
    try {
      const updateProfile = await updateSelectedPlanningProfileOfUser(payload);
    } catch (err) {
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false });
      }
    }
  };

  const dataStateChange = (event) => {
    setDataResult(process(orders, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setDataResult({
      ...dataResult,
    });
  };

  const convertmilisecondtimeformat = (duration) => {
    if (duration) {
      let date = Date.parse(duration);
 
      return (
        DateTime.fromMillis(parseInt(date))
          .setZone(location?.timezone)
          .toFormat("MM-dd-yyyy HH:mm a ZZZZ ")
          .toString()
      );
    }
  }



  const convertdatetime = (epoch_date, tzone) => {

    if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST" || tzone === "EDT" || tzone === "America/New_York") {
      tzone = "America/New_York"
    }
    if (!tzone || tzone === "Central Standard Time" || tzone === "CST" || tzone === "CDT" || tzone === "America/Chicago") {
      tzone = "America/Chicago"
    }
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return ""
    }
  }

  useEffect(() => {
    const deepClonedAllTankwashData = JSON.parse(JSON.stringify(data));
    let newDataValue = [];

    for (let index = 0; index < deepClonedAllTankwashData.length; index++) {
      const temp = deepClonedAllTankwashData[index];

      if (
        (props.tabSelected === 'All') ||
        (props.tabSelected === 'Pending' && temp.status === 'Pending') ||
        (props.tabSelected === 'Scheduled' && temp.status === 'Scheduled') ||
        (props.tabSelected === 'In progress' && temp.status === 'In progress') ||
        (props.tabSelected === 'Completed' && temp.status === 'Completed')
      ) {
        if (temp.charge_type) {
          temp.charge_type = temp?.charge_type === "I" ? "Intra Company" : temp?.charge_type === "O" ? "Outside Carrier" : temp.charge_type === "P" ? "Vendor Payble" : null;
        }
        
        if (temp.equip_type) {
          // temp.equip_type = temp.equip_type === "L" ? "Lifting Equipment" : temp.equip_type === "O" ? "Stationary Equipment" : null;

          if (temp.equip_type === "L") {
            temp.equip_type = "Trailer"
          } else if (temp.equip_type === "T") {
            temp.equip_type = "Tractor"
          } else if (temp.equip_type === "B") {
            temp.equip_type = "Trailer & Tractor"
          } else if (temp.equip_type === "O") {
            temp.equip_type = "Other"
          }
        }
  
        if (
          temp.ext_wash_amount_d === null ||
          isNaN(temp.ext_wash_amount_d)
        ) {
          temp.ext_wash_amount_d
        } else {
          temp.ext_wash_amount_d = convertdatetime(
            temp.ext_wash_amount_d, props.location?.timezone);
  
        }
        if (temp.invoice_po_date) {
          temp.invoice_po_date = convertmilisecondtimeformat(temp.invoice_po_date);
        }
  
  
        //  if(temp.in_date){
        //   temp.in_date= convertmilisecondtimeformat(temp.in_date) 
        //  }
        if (
          temp.in_date_utc === null ||
          isNaN(temp.in_date_utc)
        ) {
          temp.in_date_utc
        } else {
          temp.in_date_utc = convertdatetime(
            temp.in_date_utc, props.location?.timezone);
  
        }
        //  if(temp.int_wash_amount_d){
        //   temp.int_wash_amount_d= convertmilisecondtimeformat(temp.int_wash_amount_d) 
        //  }
        if (
          temp.int_wash_amount_d === null ||
          isNaN(temp.int_wash_amount_d)
        ) {
          temp.int_wash_amount_d
        } else {
          temp.int_wash_amount_d = convertdatetime(
            temp.int_wash_amount_d, props.location?.timezone);
  
        }
        //  if(temp.out_date){
        //   temp.out_date= convertmilisecondtimeformat(temp.out_date) 
        //  }
        if (
          temp.out_date_utc === null ||
          isNaN(temp.out_date_utc)
        ) {
          temp.out_date_utc
        } else {
          temp.out_date_utc = convertdatetime(
            temp.out_date_utc, props.location?.timezone);
  
        }
  
        if (
          temp.wash_date_utc === null ||
          isNaN(temp.wash_date_utc)
        ) {
          temp.wash_date_utc
        } else {
          temp.wash_date_utc = convertdatetime(
            temp.wash_date_utc, props.location?.timezone);
  
        }
  
        if (
          temp.needed_date_utc === null ||
          isNaN(temp.needed_date_utc)
        ) {
          temp.needed_date_utc;
        } else {
          temp.needed_date_utc = convertdatetime(
            temp.needed_date_utc, props.location?.timezone);
  
        }
  
  
        //  if(temp.start_time_utc){
        //   temp.start_time_utc= convertdatetime(temp?.start_time_utc,props.location?.timezone) 
  
        //  }
        if (
          temp.start_time_utc === null ||
          isNaN(temp.start_time_utc)
        ) {
          temp.start_time_utc;
        } else {
          temp.start_time_utc = convertdatetime(
            temp.start_time_utc, props.location?.timezone);
  
        }
        //  if(temp.end_time_utc){
        //   temp.end_time_utc= convertdatetime(temp?.end_time_utc) 
  
        //  }
        if (
          temp.end_time_utc === null ||
          isNaN(temp.end_time_utc)
        ) {
          temp.end_time_utc;
        } else {
          temp.end_time_utc = convertdatetime(
            temp.end_time_utc, props.location?.timezone);
        }

        // temp.commodity_id = '<h1></h1>';
  
        newDataValue.push(temp);
      }
      
    }

    if (props.tabSelected === 'Scheduled') newDataValue.sort((a, b) => new Date(a.start_time_utc) - new Date(b.start_time_utc));
    if (props.tabSelected === 'In progress') newDataValue.sort((a, b) => new Date(a.in_date_utc) - new Date(b.in_date_utc));
    if (props.tabSelected === 'Completed') newDataValue.sort((b, a) => new Date(a.out_date_utc) - new Date(b.out_date_utc));

    setorders(newDataValue);
    setDataResult(newDataValue)
    setIsDataLoaded(true);

  }, [data, selectedValue, props.tabSelected]);

  useEffect(() => {
    if (userData?.userpreferences?.tankwashtabsequence) {
      setcolumnorder(userData.userpreferences.tankwashtabsequence);
      setcolumnorderExcel(userData.userpreferences.tankwashtabsequence);
    } else {
      setcolumnorder(columns);
      setcolumnorderExcel(columns);
    }
  }, [userData]);

  const handleClickDateChange = (dateString) => {

    const dateObject = new Date(dateString);
    const timestamp = dateObject.getTime();
    const weekdayNumber = dateObject.getDay();
    const weekdayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const weekday = weekdayNames[weekdayNumber];

    setselectedDate(timestamp);

    if (selectedLocation) {
      genarateHosBlocKOfLocation(selectedLocation, weekday);
    }
  }


  const CustomCell = (props) => {
    const handleClick = () => {
      handleSortChange(false)({ sort: [{ field: props.field, dir: sortDir }] }); 
      handleClickDateChange(props.dataItem[props.field]);
    };

    return (
      <td>
        <span onClick={handleClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
          {props.dataItem[props.field]}
        </span>
      </td>
    );
  };

  const handleClear = () => {
    setDataState({
      ...dataState,
      filter: {
        logic: "and",
        filters: [],
      },
      sort: [],
      skip: 0,
      take: 25,
    });
  
    const clearedData = process(orders, {
      ...dataState,
      filter: { logic: "and", filters: [] },
      sort: [],
    });
    setDataResult(clearedData);
  
    // setSortData([]);
  };

  const exportExcel = () => {
    if (_export.current) {
      const originalDataState = { ...dataState };
  
      setDataState({ ...dataState, skip: 0, take: orders.length });
  
      const processedData = process(orders, { ...dataState, skip: 0, take: orders.length }).data;
      _export.current.save(processedData);
  
      setDataState(originalDataState);
    }
  };

  const handleSortChange = (e) => {

    if (!e.sort || e.sort.length === 0) return;
  
    let field = e.sort[0].field;
    let dir = e.sort[0].dir;
    let sortedOrders = [...orders];
  
    if (field === 'needed_date_utc' ||
      field === 'wash_date_utc' ||
      field === 'int_wash_amount_d' ||
      field === 'ext_wash_amount_d' ||
      field === 'in_date_utc' ||
      field === 'out_date_utc' ||
      field === 'start_time_utc' ||
      field === 'end_time_utc') {

      const timeZoneOffsets = {
        'UTC': 0, 'GMT': 0,
        'EST': -5, 'EDT': -4,
        'CST': -6, 'CDT': -5,
        'MST': -7, 'MDT': -6,
        'PST': -8, 'PDT': -7
      };
      
      sortedOrders.sort((a, b) => {
        if (!a[field]) return 1;
        if (!b[field]) return -1;
    
        const parseToUTC = (dateString) => {
          const match = dateString.match(/(\d{2}-\d{2}-\d{4} \d{2}:\d{2}) (\w+)/);
          if (!match) return null;
  
          const datePart = match[1];
          const tzAbbreviation = match[2];
  
          const utcOffset = timeZoneOffsets[tzAbbreviation] ?? 0;
          const date = new Date(datePart + ' UTC');
          date.setHours(date.getHours() - utcOffset);
  
          return date;
        };
    
        const dateA = parseToUTC(a[field]);
        const dateB = parseToUTC(b[field]);
    
        return sortDir === 'asc' ? dateA - dateB : dateB - dateA;
      });

    } else {
      sortedOrders.sort((a, b) => {
        if (!a[field]) return 1;
        if (!b[field]) return -1;

        const aValue = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field];
        const bValue = typeof b[field] === 'string' ? b[field].toLowerCase() : b[field];

        if (aValue < bValue) return sortDir === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDir === "asc" ? 1 : -1;
        return 0;
      });
    }

    sortDir === 'asc' ? setSortDir('desc') : setSortDir('asc');

    setDataState({ ...dataState, sort: [{ field }] });
    setorders(sortedOrders);
    setDataResult(process(sortedOrders, { ...dataState, sort: [{ field }] }));
  };


  return (
    <div className='tab_inside_section'>
      <div className='tab_inside_section_top'>
        <div className='tab_inside_section_top_left'></div>
        <div className='tab_inside_section_top_right'>
          <div className="planning_board_tab_toggle">
            <MGrid
              container
              spacing={2}
              direction='column'
              alignItems='right'
            >
              {/* <MGrid item>
                <ToggleButtonGroup
                  size='small'
                  value={selectedValue}
                  exclusive
                  onChange={handleToggle}
                >
                  <ToggleButton value='left'>PLANNED</ToggleButton>
                  <ToggleButton value='center'>UNPLANNED</ToggleButton>
                </ToggleButtonGroup>
              </MGrid> */}
            </MGrid>
          </div>
          <div className='ordertabdropdown'>
            <button
              title="Export Excel"
              className='btn_white_sm ml_10'
              onClick={exportExcel}
            >
              Export to Excel
            </button>
          </div>
          <div>
              <button
                type='button'
                className='btn_white_sm ml_10 '
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          {isDataloaded === "true" ? (
            ""
          ) : (
            <Link
              to=''
              component={RouterLink}
              onClick={() => {
                setIsDataLoaded("false");
                if (selectedLocation) {
                  window.open(`/TankwashPage?wash_location_id=${selectedLocation.code}&tab_selected=${props.tabSelected}`, "_blank");
                }
              }}
            >
              <button variant='contained' type='button' className='btn_signout'>
                <i className='fa fa-external-link'></i>
              </button>
            </Link>
          )}
          <i
            className='fa fa-times'
            aria-hidden='true'
            onClick={(e) => props.settabSelected("")}
          ></i>
        </div>
      </div>
      <div className='tab_inside_section_bottom'>
      <div className="bottomright-with-nw-resize-outer">
        {isDataloaded ? (
          <LocalizationProvider language={currentLocale.language}>
            <IntlProvider locale={currentLocale.locale}>
                <ExcelExport data={dataResult.data} ref={_export}>
                  {/* {columnorder.map((col) => ( */}
                  {columnorderExcel.map((col) => (
                    <ExcelExportColumn
                      key={col.id}
                      field={col.name}
                      title={col.title}
                      width={col.width}
                    />
                  ))}
                </ExcelExport>        
                <Grid
                  sortable={true}
                  filterable={true}
                  groupable={true}
                  reorderable={true}
                  pageable={{
                    buttonCount: 4,
                    pageSizes: true,
                  }}
                  resizable={true}
                  data={process(orders, dataState)}
                  skip={dataState.skip}
                  take={dataState.take}
                  onDataStateChange={dataStateChange}
                  {...dataState}
                  detail={DetailComponent}
                  expandField='expanded'
                  onExpandChange={expandChange}
                  onColumnReorder={columnReorderChange}
                  onSortChange={handleSortChange}
                  onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                  rowRender={getRowDraggable(
                    selectedValue === "left" ? () => {} : (e, dataItem) => {
                      context.setDragFrom("ordersTable");
                      context.setDragData(dataItem);
                    },
                    selectedValue === "left" ? () => {} : (e, dataItem) => {
                      context.setDragFrom(null);
                      context.setDragData(-1);
                    }
                  )}
                >
                  <GridToolbar></GridToolbar>
                  {/* {columns.slice(1).map((it) => (
                    <GridColumn
                      key={it.id}
                      field={it.name}
                      title={it.title}
                      width={it.width}
                    />
                  ))} */}
                  {/* {columns.slice(1).map((it) => { */}
                  {columnorder.map((it) => {

                    return (
                      <GridColumn
                        key={it.id}
                        field={it.name}
                        title={it.title}
                        width={it.width}
                        cell = {
                          it.name === 'needed_date_utc' ||
                          it.name === 'wash_date_utc' ||
                          it.name === 'int_wash_amount_d' ||
                          it.name === 'ext_wash_amount_d' ||
                          it.name === 'in_date_utc' ||
                          it.name === 'out_date_utc' ||
                          it.name === 'start_time_utc' ||
                          it.name === 'end_time_utc'
                          ? CustomCell : undefined
                        }
                      />
                    );
                  })}

                </Grid>     
            </IntlProvider>
          </LocalizationProvider>
        ) : (
          <ProgressBar animated now={100} />
        )}
        </div>
      </div>
    </div>
  );
};
export default TankWashOrdersTable;
