import React, { useEffect, useState, useCallback, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import { driverPayApproveSearch } from "../../services/driverPayServer";
import { BaseConfig } from "../../baseConfig";
import debounce from 'lodash/debounce';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import AuditLogModalComponent from "./auditLogModal";
import filterOperators from "../../utils/filterOperators";


const DriverPayTransferTable = (props) => {
    const { refresh, statusCode, selectedTerminalIDs } = props;
    
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
    const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [driverPageData, setDriverPageData] = useState([]);
    const [transferredData, setTransferredData] = useState({});
    const [totalResult, setTotalResult] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerms, setSearchTerms] = useState([]);
    const [sortData,setSortData] = useState([]);

    const fieldsToUpdate = [
        "transaction_date",
        "pay_date",
        "authorized_on",
        "approved_on",
        "transfer_on",
        "effective_date",
        "created_on",
        "updated_on"
    ];

    const fieldsToField = [
        "id",
        "driver_full_name",
        "pay_code",
        "pay_uom",
        "units",
        "pay_rate",
        "units",
        "authorized_by",
        "approved_by",
        "order_id",
        "amount"
    ];

    const [dataState, setDataState] = useState({
        skip: skipNumberOfResult,
        take: takeNumberOfResult,
        filter: {
            logic: "and",
            filters: [
                {
                    "field": "updated_on",
                    "operator": "contains",
                    "value": DateTime.now().minus({ months: 1 }).toJSDate()
                }
            ],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });

    const [dataResult, setDataResult] = useState([]);


    // this function runs on every filter chnage in kendo table 
    const dataStateChange = useCallback((event) => {
        let hasUpdatedOn = false;
        const filters = event?.dataState?.filter?.filters || [];
        const sortTerms=event?.dataState?.sort || [];

        // code to check on filter updated_on is there or not
        hasUpdatedOn = filters.some(obj => obj.field === "updated_on");
        const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
        const sorts= sortTerms && sortTerms.length>0 ? sortTerms.map(({ field, dir })=>({ field, dir })) : [];
        setSearchTerms(terms);
        setSortData(sorts);
        const take = event?.dataState?.take;
        const skip = event?.dataState?.skip;
        const page = Math.floor(skip / take) + 1;
        setTakeNumberOfResult(take);
        setSkipNumberOfResult(skip);
        setPageNumber(page);
        setDataState(event.dataState);
    }, []);

    // this is api calling for approved data 
    const debouncedSearch = useCallback(
        debounce(async (searchTerms, sortData, page, size, terminalName = selectedTerminalIDs, statusName = statusCode) => {
            const search = await driverPayApproveSearch(searchTerms, sortData, page, size, terminalName, statusName);
            if (search.data.data.length <= 0) {
                setIsPageLoading(false);
                setDriverPageData(search.data.data);
                setTotalResult(search.data.count);
            }
            if (search.data.data.length > 0) {
                setDriverPageData(search.data.data);
            }
            setTotalResult(search.data.count);
            setIsPageLoading(false);
            setIsLoading(false);
        }, 1000),
        [selectedTerminalIDs, statusCode, refresh]
    );

    // Create a ref to hold the child component instance
    const auditLogComponentRef = useRef();

    // Audit log modal open handler
    const auditLogModalOpenHandler = (props) => {
        // Access the child component's function using the ref
        auditLogComponentRef.current.fetchAllAuditLogData(props.id, "driver_pay");
    };

    // this useeffect will run on adding something in filter on removing in filter
    useEffect(() => {
        // it will run when there is no filter in kendo
        if (searchTerms.length <= 0 && selectedTerminalIDs  && sortData.length<=0) {
            let newsearchTerms = [];
            let newSort=[];
            debouncedSearch(newsearchTerms, newSort, pageNumber, takeNumberOfResult, selectedTerminalIDs);
        }else if (searchTerms.length <= 0 && selectedTerminalIDs  && sortData.length>0) {
            let newsearchTerms = [];
            debouncedSearch(newsearchTerms, sortData, pageNumber, takeNumberOfResult, selectedTerminalIDs);
        }

        // it will run when there is filter in kendo
        if (searchTerms.length > 0 && selectedTerminalIDs) {

            let searchTerm;
            // remove null undefined values of fields
            searchTerm = searchTerms.filter(obj => {
                if (
                    fieldsToField.includes(obj.field) &&
                    (obj.value === null || obj.value === undefined || obj.value === "")
                ) {
                    return false; // remove the object
                }
                return true; // keep the object
            });
            // remove null undefined values of date fields
            searchTerm = searchTerm.filter(obj => {
                if (
                    fieldsToUpdate.includes(obj.field) &&
                    (obj.value === null || obj.value === undefined || obj.value === "")
                ) {
                    return false; // remove the object
                }
                return true; // keep the object
            });
            setDataState({
                skip: skipNumberOfResult,
                take: takeNumberOfResult,
                filter: {
                    logic: "and",
                    filters: searchTerm
                },
                sort:sortData,
            })
            debouncedSearch(searchTerm, sortData, pageNumber, takeNumberOfResult, selectedTerminalIDs);
        }

    }, [searchTerms, pageNumber, takeNumberOfResult, selectedTerminalIDs, statusCode, refresh,sortData]);



    // this useEffect will run on refresh and terminal change
    useEffect(() => {
        let newsearchTerms = [];
        let newSort=[];
        const currentDateTime = DateTime.now();
        const lastMonthDateTime = currentDateTime.minus({ months: 1 });
        const formattedDateTime = lastMonthDateTime.toFormat("yyyy-MM-dd HH:mm:ss");
        let updatedon = {
            field: "updated_on",
            operator: "contains",
            value: formattedDateTime
        }
        newsearchTerms.push(updatedon);


        if (newsearchTerms.length >= 0 && pageNumber >= 0 && takeNumberOfResult >= 0 && selectedTerminalIDs) {
            debouncedSearch(newsearchTerms, newSort, pageNumber, takeNumberOfResult, selectedTerminalIDs);
        }


        // after refresh filter must be clean 
        setDataState({
            skip: 0,
            take: takeNumberOfResult,
            filter: {
                logic: "and",
                filters: [
                    {
                        "field": "updated_on",
                        "operator": "contains",
                        "value": DateTime.now().minus({ months: 1 }).toJSDate()
                    }
                ],
            },
            sort: newSort,
        });
    }, [refresh, selectedTerminalIDs, statusCode]);


    // this will run for driverpagedata
    useEffect(() => {
        if (driverPageData?.length > 0 && driverPageData[0]?.driver?.driver_id && driverPageData[0]?.driver?.driver_full_name) {
            const updatedDriverPayData = driverPageData.map(item => {
                item.driverFullName = `${item?.driver?.driver_id} - ${item?.driver?.driver_name}`;
                item.payCodeDesc = `${item?.driverpaybase?.pay_code} - ${item?.driverpaybase?.pay_description}`;
                return item;
            });
            setDriverPageData(updatedDriverPayData);
        };
    }, [driverPageData?.length]);


    useEffect(() => {
        setDataResult(driverPageData);
    }, [driverPageData, selectedTerminalIDs]);

    useEffect(() => {
        setIsLoading(true);
    }, [selectedTerminalIDs, refresh]);


    let rowClickHandler = (item) => {
        setTransferredData((prevState) => {
            return { ...prevState, ...item }
        })
        setModalShow(true);
    };

    const convertdatetime = (duration) => {
        if (duration) {
            let date;
            if (isNaN(duration)) {
                date = Date.parse(duration);
            } else {
                date = parseInt(duration);
            }

            const luxonDateTime = DateTime.fromMillis(date, { zone: "utc" });
            const formattedDate = luxonDateTime.toFormat("MM/dd/yyyy");

            return formattedDate;
        } else {
            return "No Data";
        }
    };
    const convertDate=(duration)=>{
        if(duration){
          return DateTime.fromISO(duration).toFormat("MM/dd/yyyy").toString();
        }else{
          return "No Data";
        }
      }

    const convertMillisToString = (duration, tZone) => {
        if (duration) {
            let time = DateTime.fromMillis(parseInt(duration), { zone: "local" }).setZone(tZone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString();
            return time;
        }
        else {
            return "No Data"
        }
    }

    function convertMsToHms(milliseconds) {
        if (milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;
            minutes = minutes % 60;

            let hrs = hours > 1 ? `${hours} hrs` : hours === 0 ? "" : `${hours} hr`;
            let mins = minutes > 1 ? `${minutes} mins` : minutes === 0 ? "" : `${minutes} mins`;
            let secs = seconds > 1 ? `${seconds} secs` : seconds === 0 ? "" : `${seconds} secs`;
            return `${hrs} ${mins} ${secs}`;
        } else {
            return "No Data";
        }
    }


    const viewInfo = (e, data) => {
        setThumbsSwiper(null)
        if (data.additional_details) {
            setimageSrc(data.additional_details.image_url)
        }

    };


    return (
        <>
            <div className='table-responsive '>
                {!isPageLoading && !isLoading ? (driverPageData?.length >= 0 ? (
                    <>
                        <Grid
                            filter={dataState.filter}
                            filterable={true}
                            sortable={true}
                            sort={dataState.sort}
                            filterOperators={filterOperators}
                            expandField="expanded"
                            pageable={{
                                pageSizes: [25, 50, 100],
                                info: true,
                                previousNext: true,
                                buttonCount: 10,
                            }}
                            total={totalResult}
                            resizable={true}
                            skip={dataState.skip}
                            take={dataState.take}
                            data={dataResult}
                            onDataStateChange={dataStateChange}
                            onRowClick={(e) => rowClickHandler(e.dataItem)}
                        >
                            <GridColumn
                                field='Action'
                                title='Action'
                                width='150px'
                                cell={(e) => {
                                    return (
                                        <td>
                                            <button
                                                type="button"
                                                className="btn-blue_schedule "
                                                onClick={(i) => auditLogModalOpenHandler(e.dataItem)}
                                            >
                                                Audit Log
                                            </button>
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='updated_on'
                                title='Updated on'
                                width="250px"
                                filterable={true}
                                filter={"date"}
                                format="{0:MM/dd/yyyy}"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.updated_on ? convertdatetime(e.dataItem.updated_on) : "No Data"}
                                        </td>
                                    );
                                }}
                            />

                            <GridColumn
                                field='id'
                                title='Id'
                                filterable={true}
                                filter="text"
                                width="200px"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.id ? (e.dataItem.id) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field="terminal_fullName"
                                title='Terminal'
                                filterable={true}
                                width="250px"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.terminal.terminal_fullName ? e.dataItem.terminal.terminal_fullName : "No Data"}
                                        </td>
                                    );
                                }}
                            />

                            <GridColumn
                                field="driver_full_name"
                                title='Driver'
                                filterable={true}
                                width="250px"
                                cell={(e) => {
                                    const drive_FullName = `${e.dataItem.driver.driver_full_name ? e.dataItem.driver.driver_full_name : "No Data"}`;
                                    return (
                                        <td>
                                            {drive_FullName}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='pay_code'
                                title='Pay Code'
                                width="220px"
                                filterable={true}
                                cell={(e) => {
                                    return (
                                        <td>
                                            {`${e.dataItem.pay_code ? e.dataItem.pay_code : "No Data"} - ${e.dataItem.pay_description ? e.dataItem.pay_description.toUpperCase() : "No Data"}`}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='driver_comment'
                                title='Driver Comment'
                                width="220px"
                                filterable={true}
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.driver_comment ? e.dataItem.driver_comment : "No Data"}
                                        </td>
                                    );

                                }}
                            />
                            <GridColumn
                                field='pay_uom'
                                title='Unit of Measure'
                                width="150px"
                                filterable={true}
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.pay_uom ? e.dataItem.pay_uom : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='units'
                                title='Units'
                                filterable={true}
                                width="150px"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.units ? Number(e.dataItem.units).toFixed(2) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='pay_rate'
                                title='Rate'
                                width="150px"
                                filterable={true}
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.pay_rate ? parseFloat(e.dataItem.pay_rate).toFixed(2) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='amount'
                                title='Amount'
                                filterable={true}
                                width="150px"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.amount ? parseFloat(e.dataItem.amount).toFixed(2) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='transaction_date'
                                title='Transaction Date'
                                filterable={true}
                                width="250px"
                                filter={"date"}
                                format="{0:MM/dd/yyyy}"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.transaction_date ? convertDate(e.dataItem.transaction_date) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='order_id'
                                title='Order ID'
                                width="150px"
                                filterable={true}
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.order_id ? e.dataItem.order_id : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='movement_id'
                                title='Movement ID'
                                width="150px"
                                filterable={true}
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.movement_id ? e.dataItem.movement_id : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='transfer_on'
                                title='Transferred Date'
                                width="250px"
                                filterable={true}
                                filter={"date"}
                                format="{0:MM/dd/yyyy}"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.transfer_on ? convertdatetime(e.dataItem.transfer_on) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='pay_date'
                                title='Pay Date'
                                filterable={true}
                                filter={"date"}
                                format="{0:MM/dd/yyyy}"
                                width="250px"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.pay_date ? convertDate(e.dataItem.pay_date) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='approved_on'
                                title='Approved Date'
                                filterable={true}
                                width="250px"
                                filter={"date"}
                                format="{0:MM/dd/yyyy}"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.approved_on ? convertdatetime(e.dataItem.approved_on) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='approved_by'
                                title='Approved By'
                                width="250px"
                                filterable={true}
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.approved_by ? e.dataItem.approved_by : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='authorized_on'
                                title='Authorized Date'
                                width="250px"
                                filterable={true}
                                filter={"date"}
                                format="{0:MM/dd/yyyy}"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.authorized_on ? convertdatetime(e.dataItem.authorized_on) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='authorized_by'
                                title='Authorized By'
                                filterable={true}
                                width="250px"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.authorized_by ? e.dataItem.authorized_by : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='created_on'
                                title='Created On'
                                filterable={true}
                                width="250px"
                                filter={"date"}
                                format="{0:MM/dd/yyyy}"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.created_on ? convertdatetime(e.dataItem.created_on) : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                            <GridColumn
                                field='created_by'
                                title='Created By'
                                width="250px"
                                cell={(e) => {
                                    return (
                                        <td>
                                            {e.dataItem.created_by ? e.dataItem.created_by : "No Data"}
                                        </td>
                                    );
                                }}
                            />
                        </Grid>
                    </>
                ) : (
                    <div className='text-center'>No data found</div>
                )) : (
                    <div>
                        <ProgressBar animated now={100} />
                        <div className='middle loader--text1'> </div>
                    </div>
                )}
            </div>

            {/* Driver Transferred Modal */}

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                dialogClassName="Authorize_Driver_Pay_width"
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>Driver Pay Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="authorize_pay">
                        <div className="driverpay_info_general">

                            <div className="driverpay_info_genera_right">
                                <p className="driverpay_info_item2">
                                    <span className="general_info_label">Driver :</span>
                                    <span className="general_info_value">{transferredData.driver_id ? transferredData.driver_id + " - " + transferredData.driver.driver_name : "No Data"}</span>
                                </p>
                                <p className="driverpay_info_item2">
                                    <span className="general_info_label">Created On :</span>
                                    <span className="general_info_value">{transferredData?.created_on ? DateTime.fromMillis(transferredData.created_on).toFormat("MM-dd-yyyy hh:mm") : "No Data"}</span>
                                </p>
                            </div>
                            <div className="driverpay_info_genera_left">
                                <p className="driverpay_info_item2">
                                    <span className="general_info_label">Terminal:</span>
                                    <span className="general_info_value">{`${transferredData?.terminal?.terminal_fullName}`}</span>
                                </p>
                                <p className="driverpay_info_item2">
                                    <span className="general_info_label">Created By :</span>
                                    <span className="general_info_value">{transferredData?.created_by ? transferredData.created_by : "No Data"}</span>
                                </p>
                            </div>

                        </div>
                        <div className="driverpay_info_move">
                            <div className="move_info_header"><p>Pay Info</p></div>
                            <div className="move_info_content">
                                <p className="move_info_item">
                                    <span className="general_info_label">Pay Description:</span>
                                    <span className="general_info_value">{transferredData.pay_code ? transferredData.pay_code : "No Data"}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Status:</span>
                                    <span className="general_info_value">{transferredData.status}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Units:</span>
                                    <span className="general_info_value">{transferredData.units ? `${transferredData.units}` : "No Data"}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Units Of Measure:</span>
                                    <span className="general_info_value">No Data</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Rate :</span>
                                    <span className="general_info_value">{transferredData.pay_rate}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Amount :</span>
                                    <span className="general_info_value">{Number(transferredData.amount).toFixed(2)}</span>
                                </p>
                                {
                                    transferredData?.additional_details?.image_url &&
                                    <p className="move_info_item view_image" onClick={(e) => viewInfo(e, transferredData)}>View Image</p>
                                }
                            </div>
                        </div>
                        <div className="driverpay_info_move">
                            <div className="move_info_header"><p>Move Info</p></div>
                            <div className="move_info_content">
                                <p className="driverpay_info_item2">
                                    <span className="general_info_label">Order:</span>
                                    <span className="general_info_value">{transferredData.order_id}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Movement:</span>
                                    <span className="general_info_value">{transferredData.movement_id}</span>
                                </p>

                                <div className="origin_destination_section">
                                    <div className="origin_destination_section_text">
                                        Origin
                                        <div className="line"></div>
                                    </div>
                                    <div className="origin_destination_section_text">
                                        Destination
                                        <div className="line"></div>
                                    </div>
                                </div>
                                <p className="move_info_item">
                                    <span className="general_info_label">Stop Type:</span>
                                    <span className="general_info_value">{transferredData?.movement?.pickup?.stop_type ? transferredData?.movement?.pickup?.stop_type : 'No Data'}</span>
                                </p>

                                <p className="move_info_item">
                                    <span className="general_info_label">Stop Type:</span>
                                    <span className="general_info_value">{transferredData?.movement?.delivery?.stop_type ? transferredData?.movement?.delivery?.stop_type : 'No Data'}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Address:</span>
                                    {transferredData?.movement?.pickup?.location?.code ?
                                        <span className="general_info_value">
                                            {transferredData?.movement?.pickup?.location?.code ?
                                                (`${transferredData?.movement?.pickup?.location?.code ? `${transferredData?.movement?.pickup?.location?.code} -` : ""}  ${transferredData?.movement?.pickup?.location?.location_name ? transferredData?.movement?.pickup?.location?.location_name : ""}`)
                                                : ""
                                            }
                                            <br />
                                            {transferredData?.movement?.pickup?.location?.address1 ?
                                                (`${transferredData?.movement?.pickup?.location?.address1 ? `${transferredData?.movement?.pickup?.location?.address1}` : ""}`)
                                                : ""
                                            }
                                            <br />
                                            {transferredData?.movement?.pickup?.location?.city ?
                                                (`${transferredData?.movement?.pickup?.location?.city ? `${transferredData?.movement?.pickup?.location?.city},` : ""} ${transferredData?.movement?.pickup?.location?.state ? `${transferredData?.movement?.pickup?.location?.state},` : ""} ${transferredData?.movement?.pickup?.location?.zip ? `${transferredData?.movement?.pickup?.location?.zip}` : ""} `)
                                                : ""
                                            }
                                        </span>
                                        : <span className="general_info_value">
                                            No Data</span>}
                                </p>

                                <p className="move_info_item">
                                    <span className="general_info_label">Address:</span>
                                    {transferredData?.movement?.delivery?.location?.code ?
                                        <span className="general_info_value">
                                            {transferredData?.movement?.delivery?.location?.code ?
                                                (`${transferredData?.movement?.delivery?.location?.code ? `${transferredData?.movement?.delivery?.location?.code} -` : ""}  ${transferredData?.movement?.delivery?.location?.location_name ? transferredData?.movement?.delivery?.location?.location_name : ""}`)
                                                : ""
                                            }
                                            <br />
                                            {transferredData?.movement?.delivery?.location?.address1 ?
                                                (`${transferredData?.movement?.delivery?.location?.address1 ? `${transferredData?.movement?.delivery?.location?.address1}` : ""}`)
                                                : ""
                                            }
                                            <br />
                                            {transferredData?.movement?.delivery?.location?.city ?
                                                (`${transferredData?.movement?.delivery?.location?.city ? `${transferredData?.movement?.delivery?.location?.city},` : ""} ${transferredData?.movement?.delivery?.location?.state ? `${transferredData?.movement?.delivery?.location?.state},` : ""} ${transferredData?.movement?.delivery?.location?.zip ? `${transferredData?.movement?.delivery?.location?.zip}` : ""} `)
                                                : ""
                                            }
                                        </span>
                                        : <span className="general_info_value">
                                            No Data</span>}
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Arrive:</span>
                                    <span className="general_info_value">{convertMillisToString(transferredData?.movement?.actual_arrive_origin, transferredData?.movement?.deliverytimezone)}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Arrive:</span>
                                    <span className="general_info_value">{convertMillisToString(transferredData?.movement?.actual_arrive_destination, transferredData?.movement?.deliverytimezone)}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Depart:</span>
                                    <span className="general_info_value">{convertMillisToString(transferredData?.movement?.actual_depart_origin, transferredData?.movement?.deliverytimezone)}</span>
                                </p>

                                <p className="move_info_item">
                                    <span className="general_info_label">Depart:</span>
                                    <span className="general_info_value">{convertMillisToString(transferredData?.movement?.actual_depart_destination, transferredData?.movement?.deliverytimezone)}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Duration (hrs):</span>
                                    <span className="general_info_value">{convertMsToHms(Math.abs(transferredData?.movement?.duration_at_origin_stop))}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Duration (hrs):</span>
                                    <span className="general_info_value">{convertMsToHms(Math.abs(transferredData?.movement?.duration_at_destination_stop))}</span>
                                </p>

                                <p className="move_info_item">
                                    <span className="general_info_label">Drive Time (hrs):</span>
                                    <span className="general_info_value">{convertMsToHms(Math.abs(transferredData?.movement?.drive_time))}</span>
                                </p>

                                <p className="move_info_item">
                                    <span className="general_info_label">Total Time (hrs):</span>
                                    <span className="general_info_value">{convertMsToHms(Math.abs(transferredData?.movement?.total_time))}</span>
                                </p>
                                <p className="move_info_item">
                                    <span className="general_info_label">Samsara Link:</span>
                                    <a href={`${BaseConfig.SamsaraDriverPayLink}${transferredData?.driver?.samsara_id}`} target="_blank">
                                        <i className="fa fa-external-link"></i>{" "} <u className="external_link">View Samsara HOS Details</u>
                                    </a>
                                </p>

                            </div>
                        </div>
                        {transferredData?.additional_details?.formatted ?
                            <>
                                <div className="driverpay_info_move">
                                    <div className="move_info_header"><p>Macro Info</p></div>
                                    <div className="move_info_content">
                                        {Object.entries(transferredData?.additional_details?.formatted).map(([label, value]) => (
                                            <p className="move_info_item" key={label}>
                                                <span className="general_info_label">{label}:</span>
                                                <span className="general_info_value">{value}</span>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </> : <></>
                        }
                        <>
                            <div className="driverpay_info_move">
                                <div className="move_info_header2"><p>Authorization Information</p></div>
                                <div className="move_info_content">
                                    <div className="driverpay_info_genera_left">
                                        <p className="move_info_item2">
                                            <span className="general_info_label">{transferredData?.authorized_comment?.startsWith("Declined") ? "Declined On" : "Authorized On"} :</span>
                                            <span className="general_info_value">{transferredData.authorized_on ? convertdatetime(transferredData.authorized_on) : "No Data"}</span>
                                        </p>
                                        <p className="move_info_item2">
                                            <span className="general_info_label">{transferredData?.authorized_comment?.startsWith("Declined") ? "Declined Comment" : "Authorized Comment"} :</span>
                                            <span className="general_info_value">{transferredData?.authorized_comment?.replace('Rejected authorize', '')?.replace('Accepted authorize', '')}</span>
                                        </p>
                                    </div>
                                    <div className="driverpay_info_genera_right">
                                        <p className="move_info_item2" >
                                            <span className="general_info_label">{transferredData?.authorized_comment?.startsWith("Declined") ? "Declined By" : "Authorized By"} :</span>
                                            <span className="general_info_value">{transferredData.authorized_by}</span>
                                        </p>
                                    </div>

                                </div>
                            </div>

                            <div className="driverpay_info_move">
                                <div className="move_info_header2"><p>Approved Information</p></div>
                                <div className="move_info_content">
                                    <div className="driverpay_info_genera_left">
                                        <p className="move_info_item2">
                                            <span className="general_info_label">{transferredData.status=='Approved' ? "Approved On" : "Rejected On"} :</span>
                                            <span className="general_info_value">{transferredData.approved_on ? convertdatetime(transferredData.approved_on) : "No Data"}</span>
                                        </p>
                                        <p className="move_info_item2">
                                            <span className="general_info_label">{transferredData.status=='Approved' ? "Approved Comment" : "Rejected Comment"} :</span>
                                            <span className="general_info_value">{transferredData?.approved_comment?.replace('Rejected:', '')?.replace('Approved:', '')}</span>
                                        </p>
                                    </div>
                                    <div className="driverpay_info_genera_right">
                                        <p className="move_info_item2" >
                                            <span className="general_info_label">{transferredData.status=='Approved' ? "Approved By" : "Rejected By"} :</span>
                                            <span className="general_info_value">{transferredData.approved_by}</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </>
                        {/* ) */}
                        {/* } */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => { setModalShow(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            
            {/* Audit log compponent */}
            <AuditLogModalComponent ref={auditLogComponentRef} />
        </>
    );
};


export default DriverPayTransferTable;
